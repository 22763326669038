import React from 'react'
import { Link } from 'react-router-dom'
import './style.scss';

const PageNotFound = () => {
  return (
    <div className='nf'>
      <div className='nf-notfound'>
        <h2 className='nf-notfound--code'>404</h2>
        <h3 className='nf-notfound--title'>Page not found</h3>
        <p className='nf-notfound--desc'>Sorry, we can&apos;t find the page you&apos;re looking for</p>
        <Link to={'/'} className='nf-notfound--btn'>back to home</Link>
      </div>

    </div>
  )
}

export default PageNotFound