import React from 'react';
import './style.scss';

const PastActivities = () => {
  return (
    <>
      {/* Section: Past Activities Details  */}
      <section className='pa-pastactivity'>
        <section className="container">
          <h1 className="global-heading mb-4">
            Debate competition
          </h1>

          <p className='pa-pastactivity--text mb-4'>Topic: </p>
          <p className='pa-pastactivity--text mb-4'>Date: </p>
          <p className='pa-pastactivity--text mb-4'>Status: </p>

          <div className='px-2'>
            <p>
              Sitare University hosted a gripping Debate Competition centered on the provocative topic, &quot;A job interview is usually a dishonest conversation, with the interviewer claiming that their company or team has the best work culture and the interviewee claiming that s/he will turn out to be their best employee.&quot; Initially, all students submitted 200-word write-ups on this theme, from which the top 12 submissions advanced to the final debate. These finalists were divided into 4 teams—2 advocating for the motion and 2 against it.
            </p>
            <p>
              The competition unfolded over three rounds: each team presented their unique perspectives, engaged in head-to-head clashes with opposing teams, and finally, the audience weighed in on the arguments presented. Ultimately, the winning team comprised Divyansh Mishra from the 2nd year, and Satyam Pandey and Mallesh Kamati from the 1st year.
            </p>
            <p>
              Throughout the debate, the central theme underscored the importance of conducting interviews as candid conversations rather than platforms for deception. The discussion also debunked the notion that &quot;faking it till you make it&quot; is a viable strategy, revealing how dishonesty can create misleading impressions and hinder genuine success in life.
            </p>
          </div>
        </section>
      </section>

      {/* Section: Winners  */}
      <section className='pa-winners'>
        <div className='container'>
          <h1 className="global-heading pa-winners--heading mb-4">
            winners
          </h1>

          <div className='pa-winners--list'>
            <div className='pa-winners--item'>
              <h3 className='pa-winners--item-position text-capitalize'>1st price</h3>
              <p className='pa-winners--item-batch text-capitalize'>student name</p>
              <p className='pa-winners--item-batch text-capitalize'>batch</p>
            </div>
            <div className='pa-winners--item'>
              <h3 className='pa-winners--item-position text-capitalize'>2nd price</h3>
              <p className='pa-winners--item-batch text-capitalize'>student name</p>
              <p className='pa-winners--item-batch text-capitalize'>batch</p>
            </div>
            <div className='pa-winners--item'>
              <h3 className='pa-winners--item-position text-capitalize'>3rd price</h3>
              <p className='pa-winners--item-batch text-capitalize'>student name</p>
              <p className='pa-winners--item-batch text-capitalize'>batch</p>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Gallery  */}
      <section className='pa-gallery'>
        <div className='container'>
          <h1 className="global-heading mb-4">
            gallery
          </h1>

          <div className='pa-gallery--list pt-3'>
            <img alt="" src='https://images.pexels.com/photos/227294/pexels-photo-227294.jpeg' />
            <img alt="" src='https://images.pexels.com/photos/1065084/pexels-photo-1065084.jpeg' />
            <img alt="" src='https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg' />
            <img alt="" src='https://images.pexels.com/photos/5234265/pexels-photo-5234265.jpeg' />
          </div>
        </div>
      </section>
    </>
  )
}

export default PastActivities;