/* eslint-disable react/prop-types */
import React from 'react';
import OwlCarousel from "react-owl-carousel";
import '../../assets/styles/_common_owlCarousel.scss';
import A79 from '../../assets/images/industry/a79.svg'
import ambient from '../../assets/images/industry/ambient.svg'
import auto_nxt from '../../assets/images/industry/autoNxt.svg'
import beans from '../../assets/images/industry/beans.svg'
// import byteup from '../../assets/images/industry/byteup.webp'
import chai_point from '../../assets/images/industry/chaiPoint.svg'
import chalo from '../../assets/images/industry/chalo.svg'
import delhivery from '../../assets/images/industry/delhivery.svg'
import digii from '../../assets/images/industry/digii.svg'
import driveu from '../../assets/images/industry/driveu.svg'
import first_hive from '../../assets/images/industry/firstHive.svg'
import genepath from '../../assets/images/industry/genePath.svg'
import glean from '../../assets/images/industry/glean.svg'
import goqii from '../../assets/images/industry/goqii.svg'
// import goveva from '../../assets/images/industry/goveva.webp'
import hiration from '../../assets/images/industry/hiration.svg'
import indihood from '../../assets/images/industry/indihood.svg'
import inmobi from '../../assets/images/industry/inmobi.svg'
import inventus_capital from '../../assets/images/industry/inventus.svg'
import iot_research from '../../assets/images/industry/iot.svg'
import klear_now from '../../assets/images/industry/klearNow.svg'
import kloudle from '../../assets/images/industry/kloudle.svg'
import leading_kart from '../../assets/images/industry/lendingKart.svg'
import mathisys from '../../assets/images/industry/mathisys.svg'
import moglix from '../../assets/images/industry/moglix.svg'
import niyo from '../../assets/images/industry/niyo.svg'
import oto from '../../assets/images/industry/oto.svg'
import proshort from '../../assets/images/industry/proshort.svg'
import scalenut from '../../assets/images/industry/scaleNut.svg'
import skyroot from '../../assets/images/industry/skyRoot.svg'
import super_tails from '../../assets/images/industry/superTails.svg'
import tracxn from '../../assets/images/industry/tracxn.svg'
import trademo from '../../assets/images/industry/trademo.svg'
import yulu from '../../assets/images/industry/yulu.svg'
import zeni from '../../assets/images/industry/zeni.svg'
import zingHR from '../../assets/images/industry/zingHR.svg'
import collage1 from '../../assets/images/industry/group_1.svg'
import collage2 from '../../assets/images/industry/group_2.svg'
import collage3 from '../../assets/images/industry/group_3.svg'
import collage4 from '../../assets/images/industry/group_4.svg'
import collage5 from '../../assets/images/industry/group_5.svg'
import collage6 from '../../assets/images/industry/group_6.svg'
import collage7 from '../../assets/images/industry/group_7.svg'
import collage8 from '../../assets/images/industry/group_8.svg'


const IND_IMG = {
    A79,
    ambient,
    auto_nxt,
    beans,
    chalo,
    chai_point,
    delhivery,
    digii,
    driveu,
    first_hive,
    genepath,
    glean,
    goqii,
    hiration,
    indihood,
    inmobi,
    inventus_capital,
    iot_research,
    klear_now,
    kloudle,
    leading_kart,
    mathisys,
    moglix,
    niyo,
    oto,
    proshort,
    scalenut,
    skyroot,
    super_tails,
    tracxn,
    trademo,
    yulu,
    zeni,
    zingHR
};

const COMPANY_LINKS = {
    A79: "https://a79.ai",
    ambient: "https://www.ambientsecurity.ai",
    auto_nxt: "https://www.autonxt.in",
    beans: "http://www.beans.ai",
    chalo: "http://chalo.com",
    chai_point: "http://shop.chaipoint.com",
    delhivery: "http://www.delhivery.com",
    digii: "http://digiicampus.com",
    driveu: "http://www.driveu.in",
    first_hive: "http://firsthive.com",
    genepath: "http://www.genepathdx.com",
    glean: "http://www.glean.com",
    goqii: "http://goqii.com",
    hiration: "https://www.hiration.com/",
    indihood: "https://www.indihood.com/",
    inmobi: "https://www.inmobi.com/",
    inventus_capital: "http://www.inventuscap.com",
    iot_research: "http://iotrl.io",
    klear_now: "https://www.klearnow.ai/",
    kloudle: "https://kloudle.com/",
    leading_kart: "http://www.lendingkart.com",
    mathisys: "http://www.mathisys-india.com",
    moglix: "http://www.moglix.com",
    niyo: "https://goniyo.com/",
    oto: "https://www.otocapital.in/",
    proshort: "https://proshort.ai/",
    scalenut: "https://www.scalenut.com/",
    skyroot: "https://skyroot.in/",
    super_tails: "https://supertails.com",
    tracxn: "http://tracxn.com",
    trademo: "https://www.trademo.com/",
    yulu: "https://www.yulu.bike/",
    zeni: "https://www.zeni.ai/",
    zingHR: "https://www.zinghr.com/"
};

const COLLAGE_ITEMS = [
    collage1, collage2, collage3, collage4, collage5, collage6, collage7, collage8
];

const CollageCarousel = ({ data }) => {
    return (
        <>
            <OwlCarousel
                id='cc_carousel'
                items={1}
                className="cc-collage--owl-theme"
                loop
                nav
                dotClass="cc-collage--carousel-dot-wrapper--carousel-dot"
                dotsClass="cc-collage--carousel-dot-wrapper"
                navIcon={["PREV", "NEXT"]}
                margin={12}
                responsive={{
                    0: {
                        items: 1,
                    },
                    576: {
                        items: 1,
                        margin: 20,
                    }
                }}
            >
                {COLLAGE_ITEMS &&
                    COLLAGE_ITEMS.length > 0 &&
                    COLLAGE_ITEMS.map((imagePath, imgInd) => (
                        <div key={imgInd} className="cc-collage--owl-theme--griditem">
                            <img className="img w-100"
                                src={imagePath}
                                alt=""
                                draggable="false"
                            />
                        </div>
                    ))}
            </OwlCarousel>
            <div className='cc-collage--list' id='cc_grid'>
                {data &&
                    data.length > 0 &&
                    data.map((imageItem, imgInd) => (
                        <div 
                            key={imgInd} className="cc-collage--listitem">
                            <a href={COMPANY_LINKS[imageItem]} target="_blank" rel="noopener noreferrer">
                                <img className="img w-100"
                                    src={IND_IMG[imageItem]}
                                    alt={imageItem}
                                    draggable="false"
                                />
                            </a>
                        </div>
                    ))}
            </div>
        </>
    );
}

export default CollageCarousel;
