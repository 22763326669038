// import { useState, useEffect } from "react";
import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
// import BannerImg1 from "../../assets/images/home/banner4_new.webp";
// import BannerImg2 from "../../assets/images/home/banner5.webp";
// import BannerImg3 from "../../assets/images/home/banner_2.webp";
import BannerImg1 from "../../assets/images/about/Cof2026.webp";
import BannerImg2 from "../../assets/images/about/Cof2027.webp";
import BannerImg3 from "../../assets/images/about/Cof2028_full.webp";
import MenuCards from "../../components/sections/MenuCards";
import { MENU_CARDS_DATA } from "../../constants";
import { Link, useNavigate } from "react-router-dom";
import "./style.scss";

const images = [
  {
    src: BannerImg1,
    description: "Empowering students with cutting-edge technology education to shape the future.",
  },
  {
    src: BannerImg2,
    description: "Creating a community of innovators and problem solvers through world-class mentorship.",
  },
  {
    src: BannerImg3,
    description: "Transforming education with AI-driven personalized learning experiences.",
  },
];

const AboutUs = () => {
  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    if (route.includes("http:") || route.includes("https:")) {
      window.open(route, "_blank");
    } else {
      navigate(`/${route}`);
    }
  };

  return (
    <>
      {/* Section: Banners */}
      <section className="mb-md-5 sa--carousel">
        <OwlCarousel
          className="owl-theme"
          loop
          margin={20}
          nav
          dots
          dotsClass="carousel-dot-wrapper"
          dotClass="carousel-dot"
          navIcon={["PREV", "NEXT"]}
          items={1}
        >
          {images.map((image, index) => (
            <div key={index} className="item">
              <SecondaryBanner
                imageUrl={image.src}
                title="About Us"
                desc={image.description}
                themeColor="sh-contentimgsplit--red-theme"
              />
            </div>
          ))}
        </OwlCarousel>
      </section>

      {/* Section: About Content */}
      {/* Section: About Content */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="global-heading mb-4">What is Sitare University?</h1>
              <p className="global-bodytext mb-4">
                We are living in unprecedented times, software has transformed
                how we live. From smartphones to smart wearables, from finding
                information to buying things, from banking to healthcare, from
                listening to music to driving a car, every aspect of our life is
                impacted by computers. This trend will only accelerate with the
                new age of AI and Machine Learning. The future belongs to
                nations who can make fundamental advances in Computer Science.
              </p>

              <p className="global-bodytext mb-4">
                To ensure that the brightest minds in the world get an
                opportunity to study Computer Science, leaders from Silicon
                Valley, top American and European institutions, industry
                leaders, and venture capitalists have come together to build a
                new University focussed on Computer Science - Sitare University.
                The first Sitare batch was started in 2022 with twenty four
                students, and the second batch admitted in 2023 has forty four
                students while the third batch admitted in 2024 has one hundred
                and sixty students, and intend to gradually increase this number
                to around one thousand admissions per year in 2030. Sitare
                students are selected based upon their JEE mains performance, as
                well as an interview.
              </p>

              <h1 className="global-heading my-5">
                Sitare University provides high quality Computer Science
                education to all bright students from underprivileged
                backgrounds FREE of cost.
              </h1>

              <p className="global-bodytext mb-4">
                The curriculum at Sitare University focuses on industry oriented
                CS education. Our students learn Python, Math, Data Structures
                and English Communication in first year; Algorithms, Java,
                Systems, Databases/IR, and Machine Learning in second year;
                Frontend, Systems, and more Machine Learning in third year
                alongside Hands-on Projects and Problem Solving. The fourth year
                is to be spent in the industry. Our students get paid
                internships right from the first summer itself. Sitare
                University will focus on five specialized areas, especially
                relevant within Computer Science.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section: About highlight points */}
      <section className="ul-facilites">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="ul-facilites--list">
                <li>
                  <b className="global-subheading global-fw600">
                    Artificial Intelligence
                  </b>
                  <p className="global-bodytext">
                    With focus on Machine Learning, Computer Vision, Natural Language Processing, and Deep Learning.
                  </p>
                </li>
                <li>
                  <b className="global-subheading global-fw600">
                    Systems
                  </b>
                  <p className="global-bodytext">
                    With focus on Cloud Computing, Operating Systems, Computer Networks, Data Management, and the Modern Internet.
                  </p>
                </li>
                <li>
                  <b className="global-subheading global-fw600">
                    Human-Computer Interaction
                  </b>
                  <p className="global-bodytext mb-3">
                    With focus on iOS and Android Development, HCI Design, Computer Graphics and Imaging, and Web Applications.
                  </p>
                </li>
                <li>
                  <b className="global-subheading global-fw600">
                    Data Science
                  </b>
                  <p className="global-bodytext">
                    With focus on data gathering, data quality, data pipelines, data in Machine Learning, data processing, and data visualization.
                  </p>
                </li>
                <li>
                  <b className="global-subheading global-fw600">
                    Computer Security
                  </b>
                  <p className="global-bodytext">
                    With focus on Authentication, System Security, Network Security, Code Security, Privacy and Policy.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Section: About Content */}
      <section className="global-aboutwrapper pb-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="global-heading mb-4">We intend to become one of the best Computer Science educational institutes in India by 2030.</h1>
              <p className="global-bodytext mb-4">
                Sitare University will be established near Indore as a full Private State University under the Madhya Pradesh Private University Act. We have started construction of our new campus on a beautiful 38 acre site (near Dewas) on Bhopal-Indore highway. We hope to move to our new campus in August 2026. For the time being, we are operating out of Lucknow. Our students have been offered admission into our partner university, Sri Ramswaroop Memorial University, in Lucknow. Our students are enrolled in a four year Bachelor of Engineering degree in Computer Science and are being taught by the faculty mentioned here in our People Section.
              </p>

              <p className="global-bodytext mb-4">
                The intent to establish Sitare University in Madhya Pradesh was <Link to={"https:bweducation.com/article/sitare-foundation-announces-sitare-university-437330"}><u>announced</u></Link> by Dr. Mohan Yadav, Hon. Chief Minister, Government of Madhya Pradesh and Smt. Yashodhara Raje Scindia, Hon. Minister for Sports and Youth Welfare, Technical Education, Skill Development and Employment, Government of Madhya Pradesh, and alongside our Founder, Dr. Amit Singhal.
              </p>

            </div>
          </div>
        </div>
      </section>


      {/* Section: What's innovation */}
      {/* <section className="global-aboutwrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 class="global-heading mb-4">
                  What’s innovative about our curriculum
                </h1>
                <p className="global-bodytext mb-3">
                  Sitare University distinguishes itself from traditional
                  institutions with its industry-focused curriculum, designed to
                  equip students for successful industry careers from day one. Our
                  curriculum balances modern job market demands with established
                  academic principles, ensuring a robust foundation for every
                  student. We are committed to nurturing high-earning
                  professionals through dedicated teaching efforts. To ensure that
                  no student is left behind, we pay individual attention to all
                  students, and provide extra help as and when required.
                </p>
  
                <p className="global-bodytext">
                  From the first year itself, our students benefit from paid
                  internships, providing invaluable real-world experience that is
                  typically hard to secure before the third year at even IITs and
                  NITs. Our faculty members not only provide strong support but
                  also foster a collaborative environment for students to learn
                  team work, supported by a network of experienced company
                  founders and advisors. As we continue to build our brand, Sitare
                  University remains dedicated to empowering students with the
                  skills and opportunities needed to excel in today's competitive
                  job market.
                </p>
              </div>
            </div>
          </div>
         </section> */}

      {/* Section: Why Sitare */}
      {/* <section className="global-aboutwrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 class="global-heading mb-4">
                  Why is Sitare University needed?
                </h1>
                <p className="global-bodytext mb-4">
                  Sitare University is deeply committed to ensuring fairness and
                  inclusivity in education. We understand the challenges posed by
                  the current JEE entrance exam system, which often disadvantages
                  students who cannot afford expensive coaching. This system tends
                  to favor those with good financial resources, thus perpetuating
                  inequalities. At Sitare University, our mission is to level the
                  playing field by providing accessible high quality educational
                  opportunities that prioritize merit and potential over economic
                  status. In addition, Sitare University fulfills a crucial role
                  in today's educational landscape for several other reasons:
                </p>
  
                <ol className="ps-3">
                  <li>
                    <p className="global-bodytext mb-2">
                      <b>Accessible Quality Education:</b> It provides high-quality
                      education that is accessible to students from diverse
                      backgrounds, including those from underprivileged
                      communities, offering opportunities that may not be readily
                      available elsewhere.
                    </p>
                  </li>
                  <li>
                    <p className="global-bodytext mb-2">
                      <b>Innovative Learning Environment:</b> Sitare University fosters
                      an environment that encourages innovation and critical
                      thinking, preparing students to thrive in a rapidly evolving
                      world driven by technological advancements.
                    </p>
                  </li>
                  <li>
                    <p className="global-bodytext mb-2">
                      <b>Holistic Development:</b> The university focuses not only on
                      academic excellence but also on the holistic development of
                      students, nurturing their innate talents, leadership skills,
                      and ethical values.
                    </p>
                  </li>
                  <li>
                    <p className="global-bodytext mb-2">
                      <b>Industry-Relevant Curriculum:</b> It offers a curriculum
                      designed in consultation with global industry experts,
                      ensuring that students are equipped with the skills and
                      knowledge necessary to meet current and future market
                      demands.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
         </section> */}

      {/* Section: Our Vision */}
      {/* <section className="container" style={{marginBottom: "50px"}}>
          <div className="row">
            <div className="col-12">
              <div className="sa-sitare--vision">
                <p>
                  Our vision is to create a fair educational environment where
                  every student, regardless of their financial background, can
                  thrive and succeed. Sitare University plays a pivotal role in
                  shaping future leaders, thinkers, and innovators who will
                  contribute positively to society, making a meaningful impact
                  globally.
                </p>
              </div>
            </div>
          </div>
         </section> */}

      {/* Section: Menu Cards */}
      <section className="global-bgmenucard">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <MenuCards
                data={MENU_CARDS_DATA}
                handleMenuClick={handleMenuClick}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
