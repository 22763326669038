/* eslint-disable react/prop-types */
import React from 'react'
import { useLocation } from 'react-router-dom'

const MenuCards = ({data, handleMenuClick, themeColor}) => {
  const location = useLocation();

  return (
    <>
        <ul className='sh-menuCard'>
            {data && data.length > 0 && data.map((item) => (
                <li key={item.id} className={`sh-menuCard--grid ${themeColor} ${item?.route !== "" && location.pathname.includes(item?.route) && "disabled" }`} onClick={() => handleMenuClick(item?.route)}>
                    <p className='sh-menuCard--gridcontent mb-0 '>{item?.name}</p>
                </li>
            ))}
        </ul>
    </>
  )
}

export default MenuCards