/* eslint-disable react/prop-types */
import React from 'react';
import "../../assets/styles/styles.scss";
import CrossIcon from '../../assets/images/placement/close.svg'
// import ImgDummy from '../../assets/images/class_room.webp'
import shravanImage from '../../assets/images/placement/story1.webp';
import divyanshImage from '../../assets/images/placement/story2.webp';
import ankitaImage from '../../assets/images/placement/story3.webp';
import ashutoshImage from '../../assets/images/placement/story4.webp';

const images = {
    divyansh: divyanshImage,
    ankita: ankitaImage,
    shravan: shravanImage,
    ashutosh: ashutoshImage
};

const InspiringStoriesDetail = ({item}) => {
    return (
        <div className="offcanvas offcanvas-end is-details" tabIndex="-1" id={`InspiringStoriesDetail${item?.id}`} aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header justify-content-start">
                <label data-bs-dismiss="offcanvas" className='wu-vacancy--close'><img src={CrossIcon} alt='icon' draggable="false" className='wu-vacancy--closeicon' />Close</label>
            </div>
            <div className="offcanvas-body is-details--body px-5">
                <h1 className="global-heading mb-4">{item?.studentName}</h1>
                <h3 className="global-subheading st-stories--item-subtitle">{item?.studentBatch}</h3>
                <p className="global-bodytext st-stories--item-desc" dangerouslySetInnerHTML={{
                    __html: item?.fullDesc.replace(/\n/g, '<br />')
                }}></p>
                <div className='is-details--images mb-4'>
                    <img 
                    src={images[item?.image]}
                    alt='Stories' draggable="false" />
                </div>
            </div>
        </div>
    )
}

export default InspiringStoriesDetail
