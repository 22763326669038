import React from 'react';
import './style.scss';
import SecondaryBanner from '../../components/sections/SecondaryBanner';
import threemonth from "../../assets/images/cocoFun/cocoFun.webp";
// import OwlCarousel from "react-owl-carousel";
// import CarouselBanner from "../../../src/assets/images/banner1.webp";
import INDUSTRY_CONNECTIONS from '../../dummyData/IndustryConnections.json';
// import PARTNERS_REVIEW_DATA from '../../dummyData/PartnersReview.json';
// import { Carousel } from "primereact/carousel";
// import { Link } from "react-router-dom";
import CollageCarousel from '../../components/sections/CollageCarousel';
import { PLACEMENTS_CARDS_DATA } from "../../constants";
import MenuCards from '../../components/sections/MenuCards';
import { useNavigate } from "react-router-dom";

const CoCoSummerOfFun = () => {

    // const responsiveOptions = [
    //     {
    //       breakpoint: '3600px',
    //       numVisible: 4,
    //       numScroll: 1,
    //     },
    //     {
    //       breakpoint: '1200px',
    //       numVisible: 3,
    //       numScroll: 1,
    //     },
    //     {
    //         breakpoint: '768px',
    //         numVisible: 2,
    //         numScroll: 1,
    //     },
    //     {
    //         breakpoint: '576px',
    //         numVisible: 1,
    //         numScroll: 1,
    //     }
    // ];

    const navigate = useNavigate();

    const handleMenuClick = (route) => {
        if (route.includes("http:") || route.includes("https:")) {
            window.open(route, "_blank");
        } else {
            navigate(`/${route}`);
        }
    };

    // const partnersReviewTemplate = (item) => {
    //     return (
    //       <div className="in-insights--carouselitem in-partners--carousel-item mx-sm-2">
    //         <img
    //           className="in-insights--carouselitem--imgfull" alt="Profile Pic"
    //           src={item?.profilePic}
    //         />
    //         <h3 className="global-subheading global-fw600 mb-4">{item?.name}</h3>
    //         <p className="in-insights--carouselitem--desc">{item?.description}</p>
    //         <button className="in-insights--carouselitem--btn">
    //           Read More
    //         </button>
    //       </div>
    //     );
    // };

    // const studentsExpTemplate = (item) => {
    //     return (
    //       <div className="in-insights--carouselitem in-partners--carousel-item mx-2">
    //         <img
    //             className="in-completed--griditem--img"
    //             src={item?.profilePic}
    //             alt="profile-pic" draggable="false"
    //         />
    //         <h3 className="global-subheading global-fw600">{item?.name}</h3>
    //         <p className="global-bodytext mb-3">Student Batch</p>
    //         <img
    //           className="in-insights--carouselitem--imgfull" alt="Profile Pic"
    //           src={item?.profilePic} draggable='false'
    //         />
    //         <p className="in-insights--carouselitem--desc">{item?.description}</p>
    //         <button className="in-insights--carouselitem--btn">
    //           Read More
    //         </button>
    //       </div>
    //     );
    // };

    return (
        <>
            {/* Section: Banner */}
            <section className='mb-5'>
                <SecondaryBanner
                    imageUrl={threemonth}
                    title="CoCo Summer of Fun"
                    desc="Three-Month Coding and Communication Focused Industry Readiness Program!"
                    themeColor="sh-contentimgsplit--red700-theme"
                />
            </section>

            {/* Section: Building Real World */}
            <section className="global-aboutwrapper">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-5 col-12">
                                    <h1 className="global-heading">
                                        Designed for Your Success
                                    </h1>
                                </div>
                                <div className="col-md-7 col-12">
                                    <h4 className='global-subheading mb-4'>Your Future Starts Here!</h4>
                                    <p className="global-bodytext">
                                        At Sitare University, we are committed to shaping future leaders in technology. CoCo Summer of Fun is your stepping stone to success, an opportunity to learn from the best and prepare for a thriving career.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Section: Purpose of Internship */}
            <section className="cp-whyinternship">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 mb-3">
                            <div className="cp-card">
                                <h1 className='global-heading mb-4'>Why CoCo Summer of Fun?</h1>
                                <p className='global-bodytext'>
                                    In today’s fast-paced industry, companies seek professionals who can think critically, communicate effectively, and solve complex problems. This program is designed to bridge the gap between academics and industry expectations, ensuring that every Sitare student graduates job-ready.                            </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                            <div className="cp-card">
                                <h1 className='global-heading mb-4'>What Makes <br /> This Program Unique?</h1>
                                <p className='global-bodytext'>
                                    Engage in real-time coding challenges, hackathons, and competitions to sharpen problem-solving skills. Learn from top tech professionals through guest lectures and Q&A sessions. Earn a prestigious certification and network with peers, faculty, and industry leaders for mentorship and career growth.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Section: Top Carousel End*/}

            {/* Section: Industry Partners */}
            <section className="container space_bottom">
                <div className="global-primeCarousel">
                    <div className="row">
                        <div className="col-12 pt-0 mb-5">
                            <h1 className="global-heading mb-4">
                                partner companies
                            </h1>
                            <p className="global-bodytext">
                                Our internship partners include well-known companies, forward-thinking startups, and research institutions committed to helping students grow. These partners offer valuable insights into industry trends and often provide a pathway for future employment.
                            </p>
                        </div>
                    </div>

                    <CollageCarousel data={INDUSTRY_CONNECTIONS} />
                </div>
            </section>


            {/* Section: Industry Partners Says */}
            {/* <section className="in-insights in-partners">
            <h1 className="global-heading in-partners--bottomspace text-center">
            What our industry partners say
            </h1>

            <div>
            <Carousel
                value={PARTNERS_REVIEW_DATA}
                numVisible={4}
                numScroll={1}
                responsiveOptions={responsiveOptions}
                className="custom-carousel in-insights--internship-carousel"
                circular={false}
                showIndicators={false}
                autoplayInterval={0}
                itemTemplate={partnersReviewTemplate}
                prevIcon={<i className="fa-solid fa-angle-left"></i>}
                nextIcon={<i className="fa-solid fa-angle-right"></i>}
                indicatorsContentClassName="gdhshds"
            />
            </div>
        </section> */}

            {/* Section: Student Support */}
            <section className="global-aboutwrapper">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-5 col-12">
                                    <h1 className="global-heading">
                                        Become an Industry-Ready Professional
                                    </h1>
                                </div>
                                <div className="col-md-7 col-12">
                                    <p className="global-bodytext">
                                        Develop strong coding skills, leadership, and communication abilities. Gain confidence in interviews, presentations, and workplace interactions to stand out as a well-rounded professional ready for the tech industry.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Section: Enquire Now */}
            {/* <section className="global-blue--strip mt-md-5">
        <div className="container">
          <div className="d-flex align-items-center gap-5">
            <div>
              <p className="global-blue--stripapplytitle">Want to know more?</p>
              <h2 className="global-blue--stripapplybtn">Enquire Now</h2>
            </div>
            
            <Link to={"https://admissions.sitare.org/"} target="_blank" className="global-blue--striparrow">
              <i className="fa-solid fa-arrow-right"></i>
            </Link>
          </div>
        </div>
      </section> */}


            {/* Section: Student Experiences */}
            {/* <section className="global-aboutwrapper">
            <div className="container">
            <div className="row justify-content-md-center">
                <div className="col-12">
                <div className="row">
                    <div className="col-md-5 col-12">
                        <h1 className="global-heading">
                            Student <br/> Experiences
                        </h1>
                    </div>
                    <div className="col-md-7 col-12">
                        <p className="global-bodytext">
                            Hear from our students! These firsthand stories highlight the practical skills, professional connections, and unforgettable experiences our students have gained through summer internships.
                        </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section> */}

            {/* Section: Industry Partners Says */}
            {/* <section className="in-insights">
            <div>
            <Carousel
                value={PARTNERS_REVIEW_DATA}
                numVisible={4}
                numScroll={1}
                responsiveOptions={responsiveOptions}
                className="custom-carousel in-insights--internship-carousel"
                circular={false}
                showIndicators={false}
                autoplayInterval={0}
                itemTemplate={studentsExpTemplate}
                prevIcon={<i className="fa-solid fa-angle-left"></i>}
                nextIcon={<i className="fa-solid fa-angle-right"></i>}
                indicatorsContentClassName="gdhshds"
            />
            </div>
        </section> */}
            <section className="container py-lg-5 mb-5">
                <MenuCards
                    data={PLACEMENTS_CARDS_DATA}
                    handleMenuClick={handleMenuClick}
                />
            </section>
        </>
    )
}

export default CoCoSummerOfFun;