import React from "react";
import "./style.scss";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
// import clubsBanner from "../../assets/images/clubs/clubBanner.webp";
import clubsBanner from "../../assets/images/clubs/poster_1.webp";
import ai_club from "../../assets/images/clubs/ai_club.webp";
import coding_club from "../../assets/images/clubs/coding_club.webp";
import chess_club from "../../assets/images/clubs/chess_club.webp";
import debate_club from "../../assets/images/clubs/debate_club.webp";
import founder_club from "../../assets/images/clubs/founders.webp";
import music_club from "../../assets/images/clubs/musicClub.webp";
import movie_club from "../../assets/images/clubs/movieClub_test.webp";
import media_club from "../../assets/images/clubs/mediaCell_test.webp";
import sports_club from "../../assets/images/clubs/sports_club.webp";
import yoga_club from "../../assets/images/clubs/yoga.webp";
import MenuCards from "../../components/sections/MenuCards";
import { EVENTS_MENU_DATA } from "../../constants";
import { useNavigate } from "react-router-dom";
// import SCHEDULE_PROGRAME from "../../dummyData/SchedulePrograme.json";
import { Carousel } from "primereact/carousel";
// import INSIGHTS_DATA from '../../dummyData/InternshipInsights.json';

const CAROUSEL_DATA = [
  {
    id: 1,
    title: "AI Club",
    desc: "Innovating with AI enthusiasts",
    bgImage: ai_club,
  },
  {
    id: 2,
    title: "Coding Club",
    desc: "Where Ideas Code into Reality",
    bgImage: coding_club,
  },
  {
    id: 3,
    title: "Chess Club",
    desc: "Every Move Matters",
    bgImage: chess_club,
  },
  {
    id: 4,
    title: "Debate Club",
    desc: "Where Words Shape The World",
    bgImage: debate_club,
  },
  {
    id: 5,
    title: "Founder's Forum",
    desc: "Where Enterpreneurs Converge",
    bgImage: founder_club,
  },
  {
    id: 6,
    title: "Music Club",
    desc: "Uniting Passionate Music Lovers",
    bgImage: music_club,
  },
  {
    id: 7,
    title: "Movie Club",
    desc: "Where Every Frame Tells A Story",
    bgImage: movie_club,
  },
  {
    id: 8,
    title: "Media Cell",
    desc: "Where Stories Come to Life",
    bgImage: media_club,
  },
  {
    id: 9,
    title: "Sports Club",
    desc: "For the Love of Game",
    bgImage: sports_club,
  },
  {
    id: 10,
    title: "Yoga Club",
    desc: "Where Body, and Spirit Unite",
    bgImage: yoga_club,
  },
]

const ClubsPage = () => {
  const navigate = useNavigate();
  const handleMenuClick = (route) => {
    if (route.includes("http:") || route.includes("https:")) {
      window.open(route, "_blank")
    } else {
      navigate(`/${route}`);
    }
  };

  const responsiveOptions = [
    {
      breakpoint: '3600px',
      numVisible: 4,
      numScroll: 1,
    },
    {
      breakpoint: '1200px',
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: '992px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '481px',
      numVisible: 1,
      numScroll: 1,
    }
  ];

  const productTemplate = (item) => {
    return (
      <div className="ei-connections--carouselitem m-2">
        <img
          className="ei-connections--carouselitem-img" alt="Profile Pic"
          src={item?.bgImage}
        />
        <div className="ei-connections--carouselitem-content text-black">
          <h3 className="global-subheading global-fw600 mb-3">{item?.title}</h3>
          <p className="ei-connections--carouselitem--desc">{item?.desc}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* Section: Banner */}
      <section>
        <SecondaryBanner
          imageUrl={clubsBanner}
          title="University Life - Events & Activities - Clubs"
          desc="Where Passion Meets Purpose."
          themeColor="sh-contentimgsplit--orange-theme"
        />
      </section>

      {/* Section: Explore Passion */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <h1 className="global-heading mb-4">
                Explore Your Passion, Build Lifelong Connections
              </h1>
              <p className="global-bodytext">
                Sitare University offers a diverse range of student clubs that
                cater to a variety of interests, from technology and
                entrepreneurship to arts and culture. These clubs provide
                students with opportunities to pursue their passions, develop
                new skills, and collaborate with like-minded individuals.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Internship Insights */}
      <section className="ei-connections">
        <div className="pt-5">
          <Carousel
            value={CAROUSEL_DATA}
            numVisible={4}
            numScroll={1}
            responsiveOptions={responsiveOptions}
            className="custom-carousel in-insights--internship-carousel"
            circular={false}
            showIndicators={false}
            autoplayInterval={0}
            itemTemplate={productTemplate}
            prevIcon={<i className="fa-solid fa-angle-left"></i>}
            nextIcon={<i className="fa-solid fa-angle-right"></i>}
            indicatorsContentClassName="gdhshds"
          />
        </div>
      </section>

      {/* Section: Why Join */}
      {/* <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <div className="row">
                <div className="col-md-5 col-12">
                  <h1 className="global-heading mb-4">Why Join a Club?</h1>
                </div>
                <div className="col-md-7 col-12">
                  <p className="global-bodytext mb-5">
                    Joining a club is the perfect way to meet new people, learn
                    outside of the classroom, and take on leadership roles.
                    Whether you’re passionate about art, coding, or making a
                    difference in the community, there’s a club for you.
                  </p>
                  <div>
                    <button type="button" class="global-orangeBtn px-5">
                      join now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* Section: Schedule Programe */}
      {/* <section className="global-events pb-0">
        <div className="container">
          <div className="row justify-content-md-center">
            <h1 className="global-heading mb-4">Upcoming Club Meetings</h1>

            <ul>
              {SCHEDULE_PROGRAME &&
                SCHEDULE_PROGRAME.length > 0 &&
                SCHEDULE_PROGRAME.map((item, index) => (
                  <li className="global-events--list">
                    <h3 className="global-events--listdate">{item?.date}</h3>
                    {item?.programs &&
                      item?.programs.length > 0 &&
                      item?.programs.map((program, ind) => (
                        <div className="global-events--listcontent">
                          <h2 className="global-events--listtime">
                            {program?.fromTime} - {program?.toTime}
                          </h2>
                          <p className="global-events--listlocation">
                            Location: {program?.location}
                          </p>
                          <p className="global-events--listtitle">
                            {program?.title}
                          </p>
                          <p className="global-bodytext">
                            {program?.desc}
                          </p>
                        </div>
                      ))}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </section> */}

      {/* Section:Menu Cards */}
      <section className="global-bgmenucard">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <MenuCards
                data={EVENTS_MENU_DATA}
                handleMenuClick={handleMenuClick}
                themeColor="sh-menuCard--orange-theme"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ClubsPage;