// menuItems.js
export const getMenuItems = (navigate) => [
  // Acadmic Life Menu Items
  {
    label: "Academic Life",
    icon: "",
    items: [
      {
        label: "Academic Life",
        // icon: "",
        // items: [
        //   {
        //     label: "",
        //     command: () => {
        //       navigate("/academic-Life");
        //     },
        //   },
        // ],
      },
      // {
      //   label: "Courses",
      //   icon: "",
      //   items: [
      //     {
      //       label: "Project",
      //       command: () => {
      //         navigate("/projects");
      //       },
      //     },
      //     {
      //       label: "Student projects",
      //       command: () => {
      //         navigate("/student-projects");
      //       },
      //     },
      //     { label: "Sitare github" },
      //     { label: "Sitare hugging face",
      //       command: () => {
      //         window.open('https://huggingface.co/', '_blank');
      //       },
      //      },
      //     {
      //       label: "Faculty projects",
      //       command: () => {
      //         navigate("/faculty-projects");
      //       },
      //     },
      //   ],
      // },
      // {
      //   label: "Admissions",
      //   icon: "",
      //   items: [
      //     { label: "How to apply",
      //       command: () => {
      //         window.open('https://admissions.sitare.org/', '_blank');
      //       },
      //      },
      //     {
      //       label: "Placements",
      //       command: () => {
      //         navigate("/placements");
      //       },
      //     },
      //     {
      //       label: "Contact Us",
      //       command: () => {
      //         navigate("/contact-us");
      //       },
      //     },
      //   ],
      // },
      {
        // label: "Academic Life",
        // icon: "",
        items: [
          {
            label: "Academic Life",
            command: () => {
              navigate("/academic-Life");
            },
          },
          {
                  label: "Curriculum",
                  command: () => {
                    navigate("/curriculum");
                  },
                },
          {
                  label: "Student Projects",
                  command: () => {
                    navigate("/student-projects");
                  },
                },
        ],
      },
    ],
    command: () => {
      // Callback to run
    },
  },

  //   University Menu Items
  {
    label: "University life",
    icon: "",
    items: [
      {
        label: "University life",
        // items: [
        //   {
        //     label: "University life",
        //     icon: "",
        //     command: () => {
        //       navigate("/facilities");
        //     },
        //   },
        // ],
      },
      {
        label: "University life",
        icon: "",
        items: [
          {
            label: "Our Campus",
            icon: "",
            command: () => {
              navigate("/facilities");
            },
          },
          {
            label: "Hostels",
            icon: "",
            command: () => {
              navigate("/hostels");
            },
          },
          {
            label: "Cafeteria",
            icon: "",
            command: () => {
              navigate("/cafeteria");
            },
          },
          // {
          //   label: "Gym",
          //   icon: "",
          //   command: () => {
          //     navigate("/gym");
          //   },
          // },
          // {
          //   label: "Sports",
          //   icon: "",
          //   command: () => {
          //     navigate("/sports");
          //   },
          // },
          {
            label: "Auditorium",
            icon: "",
            command: () => {
              navigate("/auditorium");
            },
          },
        ],
      },
      {
        label: "Activities",
        icon: "",
        items: [
          // {
          //   label: "Activities",
          //   command: () => {
          //     navigate("/events-activities");
          //   },
          // },
          // {
          //   label: "Sports_old",
          //   command: () => {
          //     navigate("/eventsports");
          //   },
          // },
          {
            label: "Clubs",
            command: () => {
              navigate("/clubs");
            },
          },
          {
            label: "Gym",
            command: () => {
              navigate("/gym");
            },
          },
          {
            label: "Sports",
            command: () => {
              navigate("/sports");
            },
          },
          // {
          //   label: "Events",
          //   command: () => {
          //     navigate("/university-event");
          //   },
          // },
          // {
          //   label: "Activities",
          //   command: () => {
          //     navigate("/class-activities");
          //   },
          // },
          // {
          //   label: "Fests",
          //   command: () => {
          //     navigate("/fests");
          //   },
          // },
         
        ],
      },
      // {
      //   label: "Hackathon",
      //   icon: "",
      //   items: []
      // }
    ],
    command: () => {
      // Callback to run
    },
  },
    // Placement Menu Items
  {
    label: "Placements",
    icon: "",
    command: () => {
      navigate("/placements");
    },
  },

  //   About Us Menu Item
  {
    label: "About Us",
    icon: "",
    items: [
      {
        label: "About Us",
        icon: "",
        // command: () => {
        //   navigate("/why-sitare");
        // },
        // items: [
        //   {
        //     label: "About Us",
        //     command: () => {
        //       navigate("/why-sitare");
        //     },
        //   },
        // ],
      },
      [
        {
          label: "Who We Are",
          icon: "",
          items: [
            {
              label: "About Us",
              command: () => {
                navigate("/why-sitare");
              },
            },
            // {
            //   label: "Why Sitare",
            //   command: () => {
            //     navigate("/why-sitare");
            //   },
            // },
            {
              label: "Who We Are",
              command: () => {
                navigate("/who-we-are");
              },
            },
            // {
            //   label: "Our Campus",
            //   command: () => {
            //     navigate("/facilities");
            //   },
            // },
            {
              label: "Our People",
              command: () => {
                navigate("/our-people");
              },
            },
            {
              label: "Gallery",
              command: () => {
                navigate("/about-gallery");
              },
            },
          ],
        },
        // {
        //   label: "Alumni",
        //   icon: "",
        //   items: [
        //     {
        //       label: "Alumni",
        //       command: () => {
        //         navigate("/alumni");
        //       },
        //     },
        //     {
        //       label: "Stay Connected",
        //       command: () => {
        //         navigate("/stay-connected");
        //       },
        //     },
        //     { label: "Alumni Events",
        //       command: () => {
        //         navigate("/alumni-events");
        //       }
        //      },
        //   ],
        // },
      ],
      // [
      //   {
      //     label: "Our People",
      //     icon: "",
      //     items: [
      //       { label: "Advisors" },
      //       { label: "Faculty" },
      //       // { label: "Administration" },
      //       { label: "Students" },
      //       { label: "Staffs" },
      //     ],
      //   },
      //   {
      //     label: "Work With Us",
      //     icon: "",
      //     items: [
      //       {
      //         label: "Academic Vacancies",
      //         command: () => {
      //           navigate("/academic-vacancies");
      //         },
      //       },
      //       {
      //         label: "Administrative Vacancies",
      //         command: () => {
      //           navigate("/administrative-vacancies");
      //         },
      //       },
      //     ],
      //   },
      // ],
      // [
      //   {
      //     label: "Our Campus",
      //     icon: "",
      //     items: [{ label: "Current Campus" },],
      //   },
      //   {
      //     label: "Gallery",
      //     icon: "",
      //     items: [
      //       {
      //         label: "Gallery",
      //         command: () => {
      //           navigate("/alumni-gallery");
      //         },
      //       },
      //     ],
      //   },
      // ],
    ],
    command: () => {
      // Callback to run
    },
  },

  //   Contact Us Menu Item
  {
    label: "Contact Us",
    icon: "",
    command: () => {
      navigate("/contact-us");
    },
    // items: [
    //   {
    //     label: "Contact Us",
    //     command: () => {
    //       navigate("/contact-us");
    //     },
    //     items: [
    //       {
    //         label: "Contact Us",
    //         command: () => {
    //           navigate("/contact-us");
    //         },
    //       },
    //     ],
    //   },
    // ],
  },

  //  Apply Now Section
  // {
  //   label: "Apply Now",
  //   icon: "",
  //   command: () => {
  //     window.open('https://admissions.sitare.org/', '_blank');
  //   },
  // },
   //  News & Update Section
  //  {
  //   label: "News & Update",
  //   icon: "",
  //   command: () => {
  //     navigate("/news-updates");
  //   },
  // },
     //  Events Section
    //  {
    //   label: "Events",
    //   icon: "",
    //   command: () => {
    //     navigate("/events-activities");
    //   },
    // },
       //  Blog Section
  //  {
  //   label: "Blogs",
  //   icon: "",
  //   command: () => {
  //     navigate("/blog");
  //   },
  // },
     //  Number Section
    //  {
    //   label: "+91 7829758672",
    //   icon: "",
    //   command: () => {
    //     navigate("");
    //   },
    // }

];
