import React from 'react';
import './style.scss';
import SecondaryBanner from '../../components/sections/SecondaryBanner';
import BannerImg from "../../assets/images/activities_banner.webp";
import MenuCards from '../../components/sections/MenuCards';
import { EVENTS_MENU_DATA } from '../../constants';
import { useNavigate } from 'react-router-dom';
import SCHEDULE_PROGRAME from '../../dummyData/SchedulePrograme.json';

const EventsInauguration = () => {
  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    if (route.includes("http:") || route.includes("https:")) {
      window.open(route, "_blank")
    } else {
      navigate(`/${route}`);
    }
  }

  return (
    <>
      {/* Section: Banner */}
      <section>
        <SecondaryBanner
          imageUrl={BannerImg}
          title="Event"
          desc="Experience the Pulse of Our Campus"
          themeColor="sh-contentimgsplit--orange-theme"
        />
      </section>

      {/* Section: Inauguration Programme */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <div className="row">
                <div className="col-md-5 col-12">
                  <h1 className="global-heading">
                    Inauguration Programme of Sitare University in 2022
                  </h1>
                </div>
                <div className="col-md-7 col-12">
                  <p className="global-bodytext">
                    Sitare University, dedicated to empowering underprivileged students through education, launched its inaugural batch for the academic years 2022-2026 in collaboration with Shri Ramswaroop Memorial University (SRMU), Lucknow. The class of 2026 included 23 bright students selected based on impressive JEE Main scores from all across India.
                  </p>
                  <p className="global-bodytext">
                    Scholarships covering tuition, lodging, boarding, and books were awarded to all students by the Sitare Foundation. Dr. Vishal Sikka, former CEO of Infosys and founder of Vianai Systems, delivered the inaugural lecture, emphasizing Sitare University&apos;s commitment to providing a robust learning environment in fields such as Artificial Intelligence, Data Science and Software Development. Dr. Amit Singhal, founder of Sitare University, highlighted the university&apos;s mission to uplift underprivileged students through a world-class Computer Science education. The inaugural event also featured insights from other startup founders, stressing the need for skilled computer scientists.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Welcoming */}
      <section className="ei-welcoming">
        <div className="container">
          <div className="row justify-content-md-center">
            <h1 className="global-heading mb-4">
              Welcoming the 2024-2028 batch
            </h1>
            <p className="global-bodytext">
              On August 20, 2024, a new chapter will begin for a group of aspiring computer scientists as they gather for their orientation day on campus. Excitement will fill the air as these students, each with their own dreams and ambitions, step onto the grounds of this prestigious institution. Orientation Day is not just an event—it&apos;s a transformative experience that marks the start of a fresh phase in the lives of all our students. It shapes perceptions, cultivates connections, and establishes the groundwork for a journey filled with discovery and growth. This day will not merely inaugurate their academic journey; it will signify a pivotal moment in their lives—a step towards realizing their dreams of becoming future leaders and innovators in the field of computer science.
            </p>

          </div>
        </div>
      </section>

      {/* Section: Schedule Programe */}
      <section className="global-events">
        <div className="container">
          <div className="row justify-content-md-center">
            <h1 className="global-heading mb-4">
              Schedule of the Program
            </h1>

            <ul>
              {SCHEDULE_PROGRAME && SCHEDULE_PROGRAME.length > 0 && SCHEDULE_PROGRAME.map((item, index) => (
                <li key={index} className='global-events--list'>
                  <h3 className='global-events--listdate'>{item?.date}</h3>
                  {item?.programs && item?.programs.length > 0 && item?.programs.map((program, index) => (
                    <div key={index} className='global-events--listcontent'>
                      <h2 className='global-events--listtime'>{program?.fromTime} - {program?.toTime}</h2>
                      <p className='global-events--listlocation'>Location: {program?.location}</p>
                      <p className='global-events--listtitle'>{program?.title}</p>
                      <p className='global-bodytext'>{program?.desc}</p>
                    </div>
                  ))}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      {/* Section: Note */}
      <section className="container">
        <div className="ei-note">
          <div className="row justify-content-md-center">
            <h2 className="ei-note--heading mb-4">
              Note:
            </h2>
            <p className="global-bodytext ei-note--desc mb-3">
              The orientation week for the B.Tech Computer Science batch of 2024 promises to be a comprehensive introduction to academic life, campus culture, and the myriad opportunities awaiting them at Sitare University. Through a blend of formal presentations, interactive sessions, and informal gatherings, students will embark on their academic journey equipped with knowledge, connections, and a sense of belonging to the Sitare community.
            </p>

          </div>
        </div>
      </section>

      <section className='container py-lg-5 mb-5 se-cards'>
        <MenuCards data={EVENTS_MENU_DATA} handleMenuClick={handleMenuClick} themeColor="sh-menuCard--orange-theme" />
      </section>
    </>
  )
}

export default EventsInauguration;