import React from "react";
import "./style.scss";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import cafebanner from "../../assets/images/cafe/cafe.webp";
import MenuCards from "../../components/sections/MenuCards";
import { FACILITIES_MENU_DATA } from "../../constants";
import { useNavigate } from "react-router-dom";

const Cafeteria = () => {
  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    if (route.includes("http:") || route.includes("https:")) {
      window.open(route, "_blank")
    } else {
      navigate(`/${route}`);
    }
  };

  return (
    <>
      {/* Section: Banner */}
      <section>
        <SecondaryBanner
          imageUrl={cafebanner}
          title="University Life - Facilities - Cafeteria"
          desc="Where Conversations Brew and Friendships Stew!"
          themeColor="sh-contentimgsplit--orange-theme"
        />
      </section>

      {/* Section: About Text */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <h1 className="global-heading mb-4">
                Healthy and Delicious Dining Options
              </h1>
              <p className="global-bodytext">
                Our cafeteria is the heart of campus life, offering a wide variety of fresh and nutritious meals. It’s the perfect place for students to refuel and relax between classes, with options that cater to diverse tastes and dietary preferences.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Facilites */}
      <section className="ul-facilites">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="ul-facilites--list">
                <li>
                  <b className="global-subheading global-fw600">
                    Cuisine Variety
                  </b>
                  <p className="global-bodytext">
                    A mix of Indian and international dishes.
                  </p>
                </li>
                <li>
                  <b className="global-subheading global-fw600">
                    Fresh and Hygienic
                  </b>
                  <p className="global-bodytext">
                    All our meals are prepared with utmost care in a safe and hygienic manner using fresh ingredients.
                  </p>
                </li>
                <li>
                  <b className="global-subheading global-fw600">
                    Cafeteria Timings
                  </b>
                  <p className="global-bodytext mb-3">
                    Food is available in the cafeteria on all 7 days of the week at fixed timings. Any changes are shared with the students well in advance.
                  </p>
                </li>
                <li>
                  <b className="global-subheading global-fw600">
                    Social Hub
                  </b>
                  <p className="global-bodytext">
                    A vibrant atmosphere where students can unwind, grab a snack, or enjoy meals with friends.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Images */}
      {/* <section className="container">
        <div className="row">
          <div className="col-12">
            <div className="ul-imgcards">
              <img src={cafe1} alt="images" draggable="false" />
              <img src={cafe2} alt="images" draggable="false" />
              <img src={cafe3} alt="images" draggable="false" />
              <img src={cafe4} alt="images" draggable="false" />
              <img src={cafe5} alt="images" draggable="false" />
              <img src={cafe6} alt="images" draggable="false" />
            </div>
          </div>
        </div>
      </section> */}

      {/* Section: Menu Cards */}
      <section className="global-bgmenucard">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <MenuCards
                data={FACILITIES_MENU_DATA}
                handleMenuClick={handleMenuClick}
                themeColor="sh-menuCard--orange-theme" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cafeteria;
