import React from "react";
import "./style.scss";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import gymbanner from "../../assets/images/gym/gym.webp";
import MenuCards from "../../components/sections/MenuCards";
// import { FACILITIES_MENU_DATA } from "../../constants";
import { EVENTS_MENU_DATA } from "../../constants";
import { useNavigate } from "react-router-dom";

const GymPage = () => {
  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    if (route.includes("http:") || route.includes("https:")) {
      window.open(route, "_blank")
    } else {
      navigate(`/${route}`);
    }
  };

  return (
    <>
      {/* Section: Banner */}
      <section>
        <SecondaryBanner
          imageUrl={gymbanner}
          title="University Life - Facilities - Gym"
          desc="Stay Fit, Stay Sharp – Fitness for Mind and Body."
          themeColor="sh-contentimgsplit--orange-theme"
        />
      </section>

      {/* Section: About Text */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <h1 className="global-heading mb-4">
                Stay Fit and Energized
              </h1>
              <p className="global-bodytext">
                At Sitare University, we believe that physical health is essential for academic success. Our state-of-the-art gym is fully equipped with the latest fitness machines and free weights, allowing students to stay active and healthy.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Facilites */}
      <section className="ul-facilites">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="ul-facilites--list">
                <li>
                  <b className="global-subheading global-fw600">
                    Modern Equipment
                  </b>
                  <p className="global-bodytext">
                    Cardio machines, strength training stations, free weights, and more.
                  </p>
                </li>
                <li>
                  <b className="global-subheading global-fw600">
                    Trainers Available
                  </b>
                  <p className="global-bodytext">
                    Certified fitness trainers are on hand to guide students in achieving their fitness goals.
                  </p>
                </li>
                <li>
                  <b className="global-subheading global-fw600">
                    Safe and Inclusive Fitness
                  </b>
                  <p className="global-bodytext mb-3">
                    Both our male and female students are welcome to use the gym. We take special measures to make sure that the gym is safe for our female students.
                  </p>
                </li>
                <li>
                  <b className="global-subheading global-fw600">
                    Gym Timings
                  </b>
                  <p className="global-bodytext">
                    The gym is open early in the morning before the classes start and in the evenings after classes are over. Actual timings are shared with the students living on campus.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Images */}
      {/* <section className="container">
        <div className="row">
          <div className="col-12">
            <div className="ul-imgcards">
              <img src={gym1} alt="images" draggable="false" />
              <img src={gym2} alt="images" draggable="false" />
              <img src={gym3} alt="images" draggable="false" />
              <img src={gym4} alt="images" draggable="false" />
              <img src={gym5} alt="images" draggable="false" />
              <img src={gym6} alt="images" draggable="false" />
            </div>
          </div>
        </div>
      </section> */}

      {/* Section: Menu Cards */}
      <section className="global-bgmenucard">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <MenuCards
                data={EVENTS_MENU_DATA}
                handleMenuClick={handleMenuClick}
                themeColor="sh-menuCard--orange-theme" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GymPage;
