import React from "react";
import "./style.scss";
import AcedmicBanner from "../../assets/images/AcedmicLife/AcedmicBanner.webp";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import MenuCards from "../../components/sections/MenuCards";
import { ACADEMIC_MENU_DATA } from "../../constants";
import { useNavigate } from "react-router-dom";

const AcademicLife = () => {
  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    if (route.includes("http:") || route.includes("https:")) {
      window.open(route, "_blank");
    } else {
      navigate(`/${route}`);
    }
  };

  return (
    <>
      <section className="mb-5">
        <SecondaryBanner
          imageUrl={AcedmicBanner}
          title="academic life"
          desc="Igniting Curiosity, Inspiring Achievement"
          themeColor="sh-contentimgsplit--yellow-theme"
        />
      </section>
      {/* Section: Text */}
      <section className="global-aboutwrapper pt-md-2 pt-0">
        <div className="container">
          <div className="row">
          <div className="col-12 mb-md-5">
                <h1 className="global-heading">Your Path to Tech Leadership Starts Here</h1>
                </div>
                <div className="col-12">
                <p className="global-bodytext mb-4">
                  At Sitare University, academic life is more than just lectures and textbooks, it’s a transformative journey that equips students to thrive in the ever-changing tech landscape. Our B.Tech program in Computer Science, designed by global technology leaders and faculty from Cornell and Stanford Universities, prepares future innovators to lead with confidence.
                  </p>
                  <p className="global-bodytext mb-4">Our curriculum strikes the perfect balance between solid theoretical foundations and immersive practical experience. With a spotlight on cutting-edge fields such as Artificial Intelligence, Machine Learning, Data Science, and Information Retrieval, we empower our students to stay at the forefront of technological advancements.</p>
                  <p className="global-bodytext mb-4">Guided by a team of distinguished faculty, comprising industry leaders and accomplished researchers, students gain invaluable real world insights. These mentors foster critical thinking, creativity, and problem solving skills, ensuring a holistic learning experience.</p>
                  <p className="global-bodytext mb-4">What sets our program apart is its strong integration with industry. Through engaging guest lectures, hands-on workshops, and seminars led by top professionals, students gain exposure to real world challenges. Our robust internship programs and industry backed projects further bridge the gap between academics and practical application.</p>
                  <p className="global-bodytext mb-4">At Sitare University, earning a degree is just the beginning. Academic life here is about growing into a well-rounded professional who is not only skilled and innovative but also socially conscious and ready to make a meaningful impact on the world.</p>
                </div>
          </div>
        </div>
      </section>

      <section className="container py-lg-5 mb-5">
        <MenuCards
          data={ACADEMIC_MENU_DATA}
          handleMenuClick={handleMenuClick}
          themeColor="sh-menuCard--yellow-theme"
        />
      </section>
    </>
  );
};

export default AcademicLife;
