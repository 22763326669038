import React from "react";
import "./style.scss";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import stayConnected from "../../assets/images/stayConnected.webp";
// import { useNavigate } from "react-router-dom";

const AlumniStayConnected = () => {
  // const navigate = useNavigate();

  return (
    <>
      {/* Section:Banner */}
      <section>
        <SecondaryBanner
          imageUrl={stayConnected}
          title="About Us - Alumni - Stay Connected"
          desc="Once Here, Connected Forever."
          themeColor="sh-contentimgsplit--green500-theme"
        />
      </section>

      {/* Section:About Text */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10">
              <p className="global-subheading">
                Stay engaged with the Sitare University community through our
                Stay Connected page. Join our alumni portal, follow us on social
                media, and participate in events and reunions. Strengthen your
                network, stay updated with university news, and reconnect with
                fellow alumni.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section:Form */}
      <section className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <form action="">
              <div className="row align-items-center mb-4">
                <div className="col-md-6 col-12">
                  <label htmlFor="" className="global-formlbl">
                    Full Name
                  </label>
                </div>
                <div className="col-md-6 col-12">
                  <input type="text" className="global-forminput" />
                </div>
              </div>

              <div className="row align-items-center mb-4">
                <div className="col-md-6 col-12">
                  <label htmlFor="" className="global-formlbl">
                    Name you studied under Sitare University{" "}
                  </label>
                </div>
                <div className="col-md-6 col-12">
                  <input type="text" className="global-forminput" />
                </div>
              </div>

              <div className="row align-items-center mb-4">
                <div className="col-md-6 col-12">
                  <label htmlFor="" className="global-formlbl">
                    Date of Birth
                  </label>
                </div>
                <div className="col-md-6 col-12">
                  <input type="date" className="global-forminput" />
                </div>
              </div>

              <div className="row align-items-center mb-4">
                <div className="col-md-6 col-12">
                  <label htmlFor="" className="global-formlbl">
                    Your student ID number
                  </label>
                </div>
                <div className="col-md-6 col-12">
                  <input
                    type="text"
                    className="global-forminput"
                    placeholder="Ususaally starts with SU001"
                  />
                </div>
              </div>

              <div className="row align-items-center mb-4">
                <div className="col-md-6 col-12">
                  <label htmlFor="" className="global-formlbl">
                    Preferred Email Address
                  </label>
                </div>
                <div className="col-md-6 col-12">
                  <input type="email" className="global-forminput" />
                </div>
              </div>

              <div className="row align-items-center mb-4">
                <div className="col-md-6 col-12">
                  <label htmlFor="" className="global-formlbl">
                    Have you secured a graduate level job?
                  </label>
                </div>
                <div className="col-md-6 col-12 d-flex gap-5">
                  <div className="form-check global-radiobtn">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="graduateLevel"
                      id="graduateLevelYes"
                      value="yes"
                    />
                    <label className="form-check-label" htmlFor="graduateLevelYes">
                      Yes
                    </label>
                  </div>
                  <div className="form-check global-radiobtn">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="graduateLevel"
                      id="graduateLevelNo"
                      value="no"
                    />
                    <label className="form-check-label" htmlFor="graduateLevelNo">
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div className="row align-items-center mb-4">
                <div className="col-md-6 col-12">
                  <label htmlFor="" className="global-formlbl">
                    Country where you currently live/ work
                  </label>
                </div>
                <div className="col-md-6 col-12">
                  <input type="text" className="global-forminput" />
                </div>
              </div>

              <div className="row align-items-center mb-4">
                <div className="col-md-6 col-12">
                  <label htmlFor="" className="global-formlbl">
                    Industry you work in
                  </label>
                </div>
                <div className="col-md-6 col-12">
                  <select className="global-forminput">
                    <option value="">Choose an option</option>
                    <option value="">option 1</option>
                  </select>
                </div>
              </div>

              <div className="row align-items-center mb-4">
                <div className="col-md-6 col-12">
                  <label htmlFor="" className="global-formlbl">
                    job title
                  </label>
                </div>
                <div className="col-md-6 col-12">
                  <input type="text" className="global-forminput" />
                </div>
              </div>

              <div className="row align-items-center mb-4">
                <div className="col-md-6 col-12">
                  <label htmlFor="" className="global-formlbl">
                    company name
                  </label>
                </div>
                <div className="col-md-6 col-12">
                  <input type="text" className="global-forminput" />
                </div>
              </div>

              <div className="row align-items-center mb-4">
                <div className="col-md-6 col-12">
                  <label htmlFor="" className="global-formlbl">
                    mobile number
                  </label>
                </div>
                <div className="col-md-6 col-12">
                  <input type="text" className="global-forminput" />
                </div>
              </div>

              <div className="row my-5">
                <div className="col-12">
                  <h4 className="global-subheading">Consent</h4>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12">
                  <label htmlFor="" className="form-check-label d-flex gap-4">
                  <input
                    type="checkbox"
                    className="global-checkbox form-check-input"
                  />
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </label>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12">
                  <label htmlFor="" className="form-check-label d-flex gap-4">
                  <input
                    type="checkbox"
                    className="global-checkbox form-check-input"
                  />
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col-12 global-section--center">
                  <button type="submit" className="global-orangeBtn">
                    submit my application
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default AlumniStayConnected;
