import React, { useEffect, useState } from 'react';
import './style.scss';
import SecondaryBanner from '../../components/sections/SecondaryBanner';
import InternshipBanner from "../../assets/images/placement/TransformBanner.webp";
import Stories from '../../components/sections/Stories';
import NEWS_ARTICLES_DATA from '../../dummyData/NewsArticles.json';

const NewsArticles = () => {
    const [totalPages, setTotalPages] = useState(0);
    // const [itemsPerPage, setItemsPerPage] = useState(6);
    const [itemsPerPage] = useState(6);
    const [currPageNumber, setCurrPageNumber] = useState(1);
    const [currentPageData, setCurrentPageData] = useState([]);

    useEffect(() => {
        let pages = Math.ceil(NEWS_ARTICLES_DATA.length / itemsPerPage);
        setTotalPages(pages);
        trimData(1, itemsPerPage);
    }, [itemsPerPage]);

    const trimData = (page, itemsPerPage) => {
        const fromInd = (page - 1) * itemsPerPage;
        const toInd = fromInd + itemsPerPage;
        const previousData = JSON.parse(JSON.stringify(NEWS_ARTICLES_DATA));
        const trimData = previousData.slice(fromInd, toInd);
        setCurrentPageData(trimData);
    };

    const handlePageNoClick = (currPage) => {
        trimData(currPage, itemsPerPage)
        setCurrPageNumber(currPage);
    }

    const handlePrevNext = (type, currPage) => {
        if (type === "Prev") {
            if (currPage > 1) {
                trimData(currPage - 1, itemsPerPage)
                setCurrPageNumber(currPage - 1);
            }
        } else {
            if (currPage < totalPages) {
                trimData(currPage + 1, itemsPerPage)
                setCurrPageNumber(currPage + 1);
            }
        }
    }

    return (
        <>
            {/* Section: Banner */}
            <section className='mb-5'>
                <SecondaryBanner
                    imageUrl={InternshipBanner}
                    title="Placements - Stories of transformation"
                    desc="Inspiring Journeys: From Classroom to Career."
                    themeColor="sh-contentimgsplit--red700-theme"
                />
            </section>

            {/* Section: Inspiring Stories */}
            <section className="st-inspiring">
                <div className="container">
                    <h1 className="global-heading">
                        Media Spotlight: Inspiring Student Success Stories from Sitare University Placements
                    </h1>

                    <div className='mt-5'>
                        <ul className='global-newscard--wrapper'>
                            {currentPageData && currentPageData.length > 0 && currentPageData.map((item, index) => (
                                <Stories key={index} data={item} />
                            ))}
                        </ul>

                    </div>
                    <div className='global-pagination d-flex justify-content-center my-5'>
                        <button className='me-3' onClick={() => handlePrevNext("Prev", currPageNumber)}>Previous</button>
                        {totalPages && totalPages > 0 && [...Array(totalPages)].map((item, index) => (
                            <div key={index} className={`global-pagination--number ${(currPageNumber === (index + 1)) && "active"}`} onClick={() => handlePageNoClick(index + 1)}>
                                <p>{index + 1}</p>
                            </div>
                        ))}
                        <button className='ms-3' onClick={() => handlePrevNext("Next", currPageNumber)}>Next</button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NewsArticles;