import React from 'react';
import './style.scss';
import SecondaryBanner from '../../components/sections/SecondaryBanner';
import InternshipBanner from "../../assets/images/placement/oneyear.webp";
import OwlCarousel from "react-owl-carousel";
import INDUSTRY_CONNECTIONS from '../../dummyData/IndustryConnections.json';
// import PARTNERS_REVIEW_DATA from '../../dummyData/PartnersReview.json';
// import { Carousel } from "primereact/carousel";
import CollageCarousel from '../../components/sections/CollageCarousel';

import { PLACEMENTS_CARDS_DATA } from "../../constants";
import MenuCards from "../../components/sections/MenuCards";
import { useNavigate } from "react-router-dom";




// const ADVISOR_DATA = [
//     "assets/images/our-people/achal-agrawal.webp",
//     "assets/images/our-people/anuja-agarwal.webp",
//     "assets/images/our-people/britney-mannas.webp",
//     "assets/images/our-people/dheerendra-mishra.webp",
//     "assets/images/our-people/jeet-mukherjee.webp",
//     "assets/images/our-people/kushal-shah.webp",
//     "assets/images/our-people/achal-agrawal.webp",
//     "assets/images/our-people/anuja-agarwal.webp",
//     "assets/images/our-people/britney-mannas.webp",
//     "assets/images/our-people/dheerendra-mishra.webp",
//     "assets/images/our-people/jeet-mukherjee.webp",
//     "assets/images/our-people/kushal-shah.webp",
// ]


const InternshipOneYear = () => {

    // const responsiveOptions = [
    //     {
    //       breakpoint: '3600px',
    //       numVisible: 4,
    //       numScroll: 1,
    //     },
    //     {
    //       breakpoint: '1200px',
    //       numVisible: 3,
    //       numScroll: 1,
    //     },
    //     {
    //         breakpoint: '1024px',
    //         numVisible: 2,
    //         numScroll: 1,
    //     },
    //     {
    //         breakpoint: '768px',
    //         numVisible: 1,
    //         numScroll: 1,
    //     }
    // ];

    const navigate = useNavigate();

    const handleMenuClick = (route) => {
        if (route.includes("http:") || route.includes("https:")) {
            window.open(route, "_blank");
        } else {
            navigate(`/${route}`);
        }
    };

    // const partnersReviewTemplate = (item) => {
    //     return (
    //       <div className="in-insights--carouselitem in-partners--carousel-item mx-2">
    //         <img
    //           className="in-insights--carouselitem--imgfull" alt="Profile Pic" draggable="false"
    //           src={item?.profilePic}
    //         />
    //         <h3 className="global-subheading global-fw600 mb-4">{item?.name}</h3>
    //         <p className="in-insights--carouselitem--desc">{item?.description}</p>
    //         <button className="in-insights--carouselitem--btn">
    //           Read More
    //         </button>
    //       </div>
    //     );
    // };

    // const studentsExpTemplate = (item) => {
    //     return (
    //       <div className="in-insights--carouselitem in-partners--carousel-item mx-2">
    //         <img
    //             className="in-completed--griditem--img"
    //             src={item?.profilePic} draggable="false" alt='profile-image'
    //         />
    //         <h3 className="global-subheading global-fw600">{item?.name}</h3>
    //         <p className="global-bodytext mb-3">Student Batch</p>
    //         <img
    //           className="in-insights--carouselitem--imgfull" alt="Profile Pic"
    //           src={item?.profilePic} draggable="false"
    //         />
    //         <p className="in-insights--carouselitem--desc">{item?.description}</p>
    //         <button className="in-insights--carouselitem--btn">
    //           Read More
    //         </button>
    //       </div>
    //     );
    // };

    // const recruitersTemplate = (item) => {
    //     return (
    //       <div className="mx-2 cp-recruiters--carouselitem">
    //         <img
    //           className="" alt="Profile Pic" draggable="false"
    //           src={item}
    //         />
    //       </div>
    //     );
    // };

    return (
        <>
            {/* Section: Banner */}
            <section className='mb-md-5'>
                <SecondaryBanner
                    imageUrl={InternshipBanner}
                    title="Placements - 12 months Internship"
                    desc="Gaining Real-World Skills with Year-Long Internships."
                    themeColor="sh-contentimgsplit--red700-theme"
                />
            </section>

            {/* Section: Full Year Immersion */}
            <section className="global-aboutwrapper">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-5 col-12">
                                    <h1 className="global-heading">
                                        Full-Year<br /> Immersion: 4th Year Internship Experience
                                    </h1>
                                </div>
                                <div className="col-md-7 col-12">
                                    <p className="global-bodytext">
                                        In their 4th year, Sitare University students embark on an immersive 12-month internship, allowing them to dive deep into their chosen fields. This experience provides them with the opportunity to work on complex projects, develop professional skills, and make meaningful contributions to their teams.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Section: Salaries */}
            <section className="container">
                <div className="cp-salaries">
                    <div className="row justify-content-md-center">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-4 col-12">
                                    <div className='cp-salaries--wrapper mb-md-0 mb-3'>
                                        <span className='cp-salaries--name'>Top <br /> CTC</span>
                                        <h1 className="global-heading cp-salaries--amount">
                                            21 LPA
                                        </h1>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className='cp-salaries--wrapper mb-md-0 mb-3'>
                                        <span className='cp-salaries--name'>Average <br /> CTC</span>
                                        <h1 className="global-heading cp-salaries--amount">
                                            11 LPA
                                        </h1>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className='cp-salaries--wrapper mb-md-0 mb-3'>
                                        <span className='cp-salaries--name'>Placement <br /> Percentage</span>
                                        <h1 className="global-heading cp-salaries--amount">
                                            91.3%
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Section: Why Internship Carousel */}
            <section className="cp-whyinternship">
                <div className='cp-whyinternship--carousel container'>
                    <OwlCarousel
                        items={2}
                        className="owl-theme"
                        loop
                        nav
                        dotClass="carousel-dot"
                        dotsClass="carousel-dot-wrapper"
                        navIcon={["PREV", "NEXT"]}
                        margin={20}
                        responsive={{
                            0: {
                                items: 1,
                            },
                            768: {
                                items: 2,
                            }
                        }}
                    >
                        <div className="position-relative cp-whyinternship--carouselitembox">
                            <div className="cp-whyinternship--carouselbannertxt">
                                <h2 className="global-heading mb-5">
                                    Why a Year-Long<br /> Internship?
                                </h2>
                                <p className="global-bodytext" data-wrap="wrap">
                                    A year-long internship offers our students a chance to engage in significant projects, develop a professional network, and gain a deeper understanding of their fields. These internships are designed to provide students with sustained, impactful experiences that enhance their employability.
                                </p>
                            </div>
                        </div>
                        <div className="position-relative cp-whyinternship--carouselitembox">
                            <div className="cp-whyinternship--carouselbannertxt">
                                <h2 className="global-heading mb-5">
                                    Industry Placement<br /> Options
                                </h2>
                                <p className="global-bodytext" data-wrap="wrap">
                                    Students can intern with multinational corporations, research labs, and innovative startups, working in areas like product development, R&D, and data analysis. By the end of the year, they’ll have built valuable skills and connections that often lead to job offers upon graduation.
                                </p>
                            </div>
                        </div>
                        <div className="position-relative cp-whyinternship--carouselitembox" >
                            <div className="cp-whyinternship--carouselbannertxt">
                                <h2 className="global-heading mb-5">
                                    Commitment for<br /> Impact
                                </h2>
                                <p className="global-bodytext" data-wrap="wrap">
                                    During the internship, students are fully integrated into their teams and participate in all aspects of their roles, from planning to execution. Both students and companies commit to making this year-long experience impactful, helping students prepare for the next step in their careers.
                                </p>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </section>
            {/* Section: Top Carousel End*/}

            {/* Section: Top Recruiters */}
            {/* <section className="container">
            <div className="cp-recruiters">
                <h1 className="global-heading text-center mb-4">
                    Our top recruiters
                </h1>
                <div>
                    <Carousel value={ADVISOR_DATA} showIndicators={false} numVisible={5} numScroll={3} itemTemplate={recruitersTemplate} />
                </div>
            </div>
        </section> */}

            {/* Section: Industry Partners */}
            <section className="container">
                <div className="global-primeCarousel">
                    <div className="row">
                        <div className="col-12 pt-0 mb-5">
                            <h1 className="global-heading mb-4">
                                partner companies
                            </h1>
                            <p className="global-bodytext">
                                Our internship partners include well-known companies, forward-thinking startups, and research institutions committed to helping students grow. These partners offer valuable insights into industry trends and often provide a pathway for future employment.
                            </p>
                        </div>
                    </div>

                    <CollageCarousel data={INDUSTRY_CONNECTIONS} />
                </div>
            </section>

            {/* Section: How It Works */}
            <section className="global-aboutwrapper cp-howitworks">
                <div className="container">
                    <h1 className="global-heading cp-howitworks--heading">
                        How It Works
                    </h1>
                    <p className="global-bodytext cp-howitworks--desc">
                        Students apply for the year-long internship through our Placement Office, which offers assistance throughout the process. From application to placement, our team is here to support each student’s journey to securing a meaningful internship.
                    </p>
                </div>
            </section>

            {/* Section: Student Experiences */}
            {/* <section className="global-aboutwrapper">
            <div className="container">
            <div className="row justify-content-md-center">
                <div className="col-12">
                <div className="row">
                    <div className="col-md-5 col-12">
                        <h1 className="global-heading">
                            Student <br/> Experiences
                        </h1>
                    </div>
                    <div className="col-md-7 col-12">
                        <p className="global-bodytext">
                            Hear from our students! These firsthand stories highlight the practical skills, professional connections, and unforgettable experiences our students have gained through summer internships.
                        </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section> */}

            {/* Section: Industry Partners Says */}
            {/* <section className="in-insights">
            <div>
            <Carousel
                value={PARTNERS_REVIEW_DATA}
                numVisible={4}
                numScroll={1}
                responsiveOptions={responsiveOptions}
                className="custom-carousel in-insights--internship-carousel"
                circular={false}
                showIndicators={false}
                autoplayInterval={0}
                itemTemplate={studentsExpTemplate}
                prevIcon={<i className="fa-solid fa-angle-left"></i>}
                nextIcon={<i className="fa-solid fa-angle-right"></i>}
                indicatorsContentClassName="gdhshds"
            />
            </div>
        </section> */}
            <section className="container py-lg-5 mb-5">
                <MenuCards
                    data={PLACEMENTS_CARDS_DATA}
                    handleMenuClick={handleMenuClick}
                />
            </section>

        </>
    )
}

export default InternshipOneYear;