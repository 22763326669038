import React from "react";
// import OwlCarousel from "react-owl-carousel";
import "./style.scss";
import "../../assets/styles/styles.scss";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import BannerImg from "../../assets/images/student_projects.webp";
import PedagogyImg from "../../assets/images/pedagogy_img.webp";
import INDUSTRY_CONNECTIONS from '../../dummyData/IndustryConnections.json'
import GrowthSteps from "../../components/sections/GrowthSteps";
import { Carousel } from "primereact/carousel";
import INSIGHTS_DATA from '../../dummyData/InternshipInsights.json';
import PARTNERS_REVIEW_DATA from '../../dummyData/PartnersReview.json'
import CollageCarousel from "../../components/sections/CollageCarousel";

const Internships = () => {

  const responsiveOptions = [
    {
      breakpoint: '3600px',
      numVisible: 4,
      numScroll: 1,
    },
    {
      breakpoint: '1200px',
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: '1024px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '768px',
      numVisible: 1,
      numScroll: 1,
    }
  ];


  const productTemplate = (item) => {
    return (
      <div className="in-insights--carouselitem m-2">
        <img
          className="in-insights--carouselitem--img" alt="Profile Pic"
          src={item?.profilePic} draggable="false"
        />
        <h3 className="global-subheading global-fw600 mt-3">{item?.name}</h3>
        <p className="global-bodytext mb-4 mt-2">{item?.batch}</p>
        <p className="in-insights--carouselitem--desc">{item?.description}</p>
        <button className="in-insights--carouselitem--btn">
          Read More
        </button>
      </div>
    );
  };

  const partnersReviewTemplate = (item) => {
    return (
      <div className="in-insights--carouselitem in-partners--carousel-item">
        <img
          className="in-insights--carouselitem--imgfull" alt="Profile Pic"
          src={item?.profilePic} draggable="false"
        />
        <h3 className="global-subheading global-fw600 mb-4">{item?.name}</h3>
        <p className="in-insights--carouselitem--desc">{item?.description}</p>
        <button className="in-insights--carouselitem--btn">
          Read More
        </button>
      </div>
    );
  };



  return (
    <>
      {/* Section: Banner */}
      <section>
        <SecondaryBanner
          imageUrl={BannerImg}
          title="Internships"
          desc="Lorem ipsum dolor sit amet "
          themeColor="sh-contentimgsplit--yellow-theme"
        />
      </section>

      {/* Section: Real World Content */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <div className="row">
                <div className="col-md-5 col-12">
                  <h1 className="global-heading">
                    Real-World Experience: Internships at Sitare University
                  </h1>
                </div>
                <div className="col-md-7 col-12">
                  <p className="global-bodytext">
                    Internships are a pivotal aspect of the experiential
                    learning framework at Sitare University’s Computer Science
                    program. The university collaborates with a diverse array of
                    industry partners, ranging from leading tech companies to
                    innovative startups, to provide students with meaningful
                    internship opportunities right after their first year of
                    studies. These internships enable students to gain hands-on
                    experience, apply classroom knowledge in real-world
                    settings, and develop critical professional skills. Sitare
                    University’s faculty play a vital role in supporting
                    students through the internship process, offering resources
                    such as resume building training, interview preparation, and
                    career counseling. Internships not only enhance students&apos;
                    technical expertise but also expose them to workplace
                    dynamics, project management practices, and industry
                    standards. This practical experience is invaluable in
                    preparing students for successful careers in the tech
                    industry, equipping them with the skills and confidence
                    needed to excel in their chosen fields.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Pedagogy Banner */}
      <section className="sp-pedagogybanner mb-5">
        <div className="container">
          <img
            src={PedagogyImg}
            alt="Pedagogy"
            className="sp-pedagogybanner--image"
            draggable="false"
          />
        </div>
      </section>

      {/* Section: Industry Partners */}
      <section className="container">
        <div className="global-primeCarousel">
          <div className="row">
            <div className="col-12 global-section--center pt-0">
              <h1 className="global-heading text-center">
                Our industry partners
              </h1>
            </div>
          </div>

          <CollageCarousel data={INDUSTRY_CONNECTIONS} />
        </div>
      </section>

      {/* Section: Internship Insights */}
      <section className="in-insights">
        <h1 className="global-heading mb-5 text-center">
          Internship insights: student experiences
        </h1>
        <div className="pt-5">
          <Carousel
            value={INSIGHTS_DATA}
            numVisible={4}
            numScroll={1}
            responsiveOptions={responsiveOptions}
            className="custom-carousel in-insights--internship-carousel"
            circular={false}
            showIndicators={false}
            autoplayInterval={0}
            itemTemplate={productTemplate}
            prevIcon={<i className="fa-solid fa-angle-left"></i>}
            nextIcon={<i className="fa-solid fa-angle-right"></i>}
            indicatorsContentClassName="gdhshds"
          />
        </div>
      </section>

      {/* Section: Rewarding Growth */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <h1 className="global-heading">
                Rewarding <br /> growth while you <br /> learn
              </h1>
              <p className="global-bodytext py-3">
                At Sitare University, we are committed to providing students
                with valuable internship experiences, which include competitive
                stipends. These stipends are designed to not only reward
                students for their hard work but also to support them
                financially as they gain practical industry experience. Here&apos;s a
                breakdown of the stipend structure for each year of the Computer
                Science program
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <GrowthSteps bgTheme={"yellow"} />
            </div>
          </div>
        </div>
      </section>

      {/* Section: Pedagogy Banner */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <img
                src={PedagogyImg}
                alt="section-Image"
                className="sp-pedagogybanner--image"
                draggable="false"
              />
            </div>
          </div>
        </div>
      </section>


      {/* Section: Industry Partners Says */}
      <section className="in-insights in-partners">
        <h1 className="global-heading in-partners--bottomspace text-center">
          What our industry partners say
        </h1>

        <div>
          <Carousel
            value={PARTNERS_REVIEW_DATA}
            numVisible={4}
            numScroll={1}
            responsiveOptions={responsiveOptions}
            className="custom-carousel in-insights--internship-carousel"
            circular={false}
            showIndicators={false}
            autoplayInterval={0}
            itemTemplate={partnersReviewTemplate}
            prevIcon={<i className="fa-solid fa-angle-left"></i>}
            nextIcon={<i className="fa-solid fa-angle-right"></i>}
            indicatorsContentClassName="gdhshds"
          />
        </div>
      </section>


      {/* Section: Rewarding Growth */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <h1 className="global-heading">
                Rewarding <br /> growth while you <br /> learn
              </h1>
              <p className="global-bodytext py-3">
                At Sitare University, we are committed to providing students
                with valuable internship experiences, which include competitive
                stipends. These stipends are designed to not only reward
                students for their hard work but also to support them
                financially as they gain practical industry experience. Here&apos;s a
                breakdown of the stipend structure for each year of the Computer
                Science program
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <GrowthSteps bgTheme={"green"} />
            </div>
          </div>
        </div>
      </section>

      {/* Section: Internships Completed */}
      <section className="in-completed">
        <div className="container">
          <h1 className="global-heading mb-5 text-center">
            internships completed by students
          </h1>

          <div className="in-completed--grid">
            <div className="in-completed--griditem">
              <img
                className="in-completed--griditem--img"
                src="https://images.pexels.com/photos/1065084/pexels-photo-1065084.jpeg"
                alt="" draggable="false"
              />
              <h3 className="global-subheading global-fw600">Amelia</h3>
              <p className="global-bodytext">Student Batch</p>
              <p className="in-completed--griditem--desc">
                Internship: <br /> Web development
              </p>
            </div>
            <div className="in-completed--griditem">
              <img
                className="in-completed--griditem--img" draggable="false" alt=""
                src="https://images.pexels.com/photos/227294/pexels-photo-227294.jpeg"
              />
              <h3 className="in-completed--griditem--heading">Hazel</h3>
              <p className="in-completed--griditem--title">Student Batch</p>
              <p className="in-completed--griditem--desc">
                Internship: <br /> Web development
              </p>
            </div>
            <div className="in-completed--griditem">
              <img
                className="in-completed--griditem--img" alt="" draggable="false"
                src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg"
              />
              <h3 className="in-completed--griditem--heading">Olivia</h3>
              <p className="in-completed--griditem--title">Student Batch</p>
              <p className="in-completed--griditem--desc">
                Internship: <br /> Web development
              </p>
            </div>
            <div className="in-completed--griditem">
              <img
                className="in-completed--griditem--img" alt="" draggable="false"
                src="https://images.pexels.com/photos/5234265/pexels-photo-5234265.jpeg"
              />
              <h3 className="in-completed--griditem--heading">Joseph</h3>
              <p className="in-completed--griditem--title">Student Batch</p>
              <p className="in-completed--griditem--desc">
                Internship: <br /> Web development
              </p>
            </div>
            <div className="in-completed--griditem">
              <img
                className="in-completed--griditem--img" alt="" draggable="false"
                src="https://images.pexels.com/photos/5234265/pexels-photo-5234265.jpeg"
              />
              <h3 className="in-completed--griditem--heading">Joseph</h3>
              <p className="in-completed--griditem--title">Student Batch</p>
              <p className="in-completed--griditem--desc">
                Internship: <br /> Web development
              </p>
            </div>
            <div className="in-completed--griditem">
              <img
                className="in-completed--griditem--img" alt="" draggable="false"
                src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg"
              />
              <h3 className="in-completed--griditem--heading">Olivia</h3>
              <p className="in-completed--griditem--title">Student Batch</p>
              <p className="in-completed--griditem--desc">
                Internship: <br /> Web development
              </p>
            </div>
            <div className="in-completed--griditem">
              <img
                className="in-completed--griditem--img" alt="" draggable="false"
                src="https://images.pexels.com/photos/227294/pexels-photo-227294.jpeg"
              />
              <h3 className="in-completed--griditem--heading">Hazel</h3>
              <p className="in-completed--griditem--title">Student Batch</p>
              <p className="in-completed--griditem--desc">
                Internship: <br /> Web development
              </p>
            </div>
            <div className="in-completed--griditem">
              <img
                className="in-completed--griditem--img" alt="" draggable="false"
                src="https://images.pexels.com/photos/1065084/pexels-photo-1065084.jpeg"
              />
              <h3 className="in-completed--griditem--heading">Amelia</h3>
              <p className="in-completed--griditem--title">Student Batch</p>
              <p className="in-completed--griditem--desc">
                Internship: <br /> Web development
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-5">
            <button className="in-completed--load-btn">load more</button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Internships;
