import React, { useEffect, useState } from "react";
import "./style.scss";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import TransformBanner from "../../assets/images/placement/TransformBanner.webp";
// import OwlCarousel from "react-owl-carousel";
// import CarouselBanner from "../../../src/assets/images/banner1.jpg";
// import INDUSTRY_CONNECTIONS from "../../dummyData/IndustryConnections.json";
// import PARTNERS_REVIEW_DATA from "../../dummyData/PartnersReview.json";
// import { Carousel } from "primereact/carousel";
// import { Link } from "react-router-dom";

import { PLACEMENTS_CARDS_DATA } from "../../constants";
import MenuCards from "../../components/sections/MenuCards";
import { useNavigate } from "react-router-dom";

// import NEWS_ARTICLES_DATA from "../../dummyData/NewsArticles.json";
// import Stories from "../../components/sections/Stories";
import InspiringStoriesComp from "../../components/sections/InspiringStoriesComp";
import INSPIRING_STORIES from "../../dummyData/InspiringStories.json";

const TransformationStories = () => {
  // const [isLoadMore, setIsLoadMore] = useState(false);
  // const [isLoadMore] = useState(false);
  // const [storiesData, setStoriesData] = useState([]);
  // const [storiesStartCount, setStoriesStartCount] = useState(0);
  // const [storiesStartCount] = useState(0);
  // const [storiesEndCount, setStoriesEndCount] = useState(4);
  // const [storiesEndCount] = useState(4);
  const [inspiringData, setInspiringData] = useState([]);
  // const [inspiringStartCount, setInspiringStartCount] = useState(0);
  const [inspiringStartCount] = useState(0);
  // const [inspiringEndCount, setInspiringEndCount] = useState(5);
  const [inspiringEndCount] = useState(5);

  // useEffect(() => {
  //   let data =
  //     NEWS_ARTICLES_DATA &&
  //     NEWS_ARTICLES_DATA.slice(storiesStartCount, storiesEndCount);
  //   setStoriesData([...data]);
  // }, [storiesStartCount, storiesEndCount]);

  useEffect(() => {
    let data =
      INSPIRING_STORIES &&
      INSPIRING_STORIES.slice(inspiringStartCount, inspiringEndCount);
    setInspiringData([...data]);
  }, [inspiringStartCount, inspiringEndCount]);

  // const handleLoadStories = (strtCount, endCount) => {
  //   setStoriesStartCount(endCount);
  //   setStoriesEndCount(endCount + 4);
  // };

  // const handleLoadInspiring = (strtCount, endCount) => {
  //   setInspiringStartCount(endCount);
  //   setInspiringEndCount(endCount + 5);
  // };

  // const responsiveOptions = [
  //   {
  //     breakpoint: "3600px",
  //     numVisible: 4,
  //     numScroll: 1,
  //   },
  //   {
  //     breakpoint: "1200px",
  //     numVisible: 3,
  //     numScroll: 1,
  //   },
  //   {
  //     breakpoint: "1024px",
  //     numVisible: 2,
  //     numScroll: 1,
  //   },
  //   {
  //     breakpoint: "576px",
  //     numVisible: 1,
  //     numScroll: 1,
  //   },
  // ];

  // const responsiveOption = [
  //   {
  //     breakpoint: '3600px',
  //     numVisible: 1,
  //     numScroll: 1,
  //   },
  //   {
  //     breakpoint: '1200px',
  //     numVisible: 3,
  //     numScroll: 1,
  //   },
  //   {
  //       breakpoint: '1024px',
  //       numVisible: 2,
  //       numScroll: 1,
  //   },
  //   {
  //     breakpoint: "576px",
  //     numVisible: 1,
  //     numScroll: 1,
  //   },
  // ];

  // const CAROUSEL_DATA = [
  //   {
  //     id: 1,
  //     title: "Leader name",
  //     desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.",
  //     image:
  //       "https://images.pexels.com/photos/1065084/pexels-photo-1065084.jpeg",
  //   },
  //   {
  //     id: 2,
  //     title: "Leader name 2",
  //     desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.",
  //     image: "https://images.pexels.com/photos/227294/pexels-photo-227294.jpeg",
  //   },
  //   {
  //     id: 3,
  //     title: "Leader name 3",
  //     desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.",
  //     image: "https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg",
  //   },
  //   {
  //     id: 4,
  //     title: "Leader name 4",
  //     desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.",
  //     image:
  //       "https://images.pexels.com/photos/5234265/pexels-photo-5234265.jpeg",
  //   },
  // ];

  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    if (route.includes("http:") || route.includes("https:")) {
      window.open(route, "_blank");
    } else {
      navigate(`/${route}`);
    }
  };

  // const productTemplate = (item) => {
  //   return (
  //     <div className="ts-leaders--carouselitem m-md-2">
  //       <img
  //         className="ts-leaders--carouselitem-img"
  //         alt="Profile Pic"
  //         src={item?.image}
  //         draggable='false'
  //       />
  //       <div className="ts-leaders--carouselitem-content">
  //         <h3 className="global-subheading global-fw600 mb-3">{item?.title}</h3>
  //         <p className="ts-leaders--carouselitem--desc global-lineclamp mb-0">{item?.desc}</p>
  //       </div>
  //     </div>
  //   );
  // };

  // const partnersReviewTemplate = (item) => {
  //   return (
  //     <div className="in-insights--carouselitem in-partners--carousel-item mx-2">
  //       <img
  //         className="in-insights--carouselitem--imgfull"
  //         alt="Profile Pic"
  //         src={item?.profilePic}
  //         draggable='false'
  //       />
  //       <h3 className="global-subheading global-fw600 mb-4">{item?.name}</h3>
  //       <p className="in-insights--carouselitem--desc">{item?.description}</p>
  //       <button className="in-insights--carouselitem--btn">Read More</button>
  //     </div>
  //   );
  // };

  // const studentsExpTemplate = (item) => {
  //   return (
  //     <div className="in-insights--carouselitem in-partners--carousel-item mx-2">
  //       <img className="in-completed--griditem--img" src={item?.profilePic} />
  //       <h3 className="global-subheading global-fw600">{item?.name}</h3>
  //       <p className="global-bodytext mb-3">Student Batch</p>
  //       <img
  //         className="in-insights--carouselitem--imgfull"
  //         alt="Profile Pic"
  //         src={item?.profilePic}
  //       />
  //       <p className="in-insights--carouselitem--desc">{item?.description}</p>
  //       <button className="in-insights--carouselitem--btn">Read More</button>
  //     </div>
  //   );
  // };

  return (
    <>
      {/* Section: Banner */}
      <section>
        <SecondaryBanner
          imageUrl={TransformBanner}
          title="Placements - Stories of transformation"
          desc="Transformative Journeys: The Sitare Shines."
          themeColor="sh-contentimgsplit--red700-theme"
        />
      </section>

      {/* Section: Building Real World */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="global-bodytext global-fw800">
                At Sitare University, we are proud to share the inspiring
                journeys of our students who have transformed their lives
                through education. These stories showcase resilience, ambition,
                and the power of education to change lives.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Modest Begining */}
      <section className="global-aboutwrapper ts-red100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* <p className="global-heading"> */}
              <p className="global-subheading global-fw600">
                Rising Beyond Limits: Witness the incredible transformations of students who turned challenges into triumphs with Sitare University.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Media Spotlight */}
      {/* <section className="ts-media">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h4 className="global-heading mb-0">
                Media Spotlight: Inspiring Student Success Stories from Sitare
                University Placements
              </h4>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <ul className="global-newscard--wrapper">
                {storiesData &&
                  storiesData.length > 0 &&
                  storiesData.map((item, index) => (
                    <Stories key={index} data={item} />
                  ))}
              </ul>

              <div className="mb-md-5 d-flex justify-content-center">
                <button
                  className="ts-btn"
                  onClick={() =>
                    handleLoadStories(storiesStartCount, storiesEndCount)
                  }
                >
                  view all articles
                </button>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* Section: Internship Insights */}
      {/* <section className="ts-leaders">
        <div className="container">
          <div className="row">
            <div className="col-12 px-0">
              <h4 className="global-heading">
                Global Leaders <br />
                Sitare University's Impact
              </h4>
              <div className="pt-5">
                <Carousel
                  value={CAROUSEL_DATA}
                  numVisible={1}
                  numScroll={1}
                  responsiveOptions={responsiveOption}
                  className="custom-carousel in-insights--internship-carousel"
                  circular={false}
                  showIndicators={false}
                  autoplayInterval={0}
                  itemTemplate={productTemplate}
                  prevIcon={<i className="fa-solid fa-angle-left"></i>}
                  nextIcon={<i className="fa-solid fa-angle-right"></i>}
                  indicatorsContentClassName="gdhshds"
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* section:inspiring stories */}
      <section className="ts-inspire">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h4 className="global-heading">Inspiring Stories</h4>
              <div>
                <ul className="global-newscard--wrapper">
                  {inspiringData &&
                    inspiringData.length > 0 &&
                    inspiringData.map((item, index) => (
                      <InspiringStoriesComp key={index} data={item} />
                    ))}
                </ul>

                {/* <div className="mb-md-5 d-flex justify-content-center">
                <button
                  className="ts-btn"
                  onClick={() =>
                    handleLoadInspiring(inspiringStartCount, inspiringEndCount)
                  }
                >
                  view all articles
                </button>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container py-lg-5 mb-5">
        <MenuCards
          data={PLACEMENTS_CARDS_DATA}
          handleMenuClick={handleMenuClick}
        />
      </section>
    </>
  );
};

export default TransformationStories;
