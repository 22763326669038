import React from "react";
import "./style.scss";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import alumniEvents from "../../assets/images/alumniEvents.webp";
import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
const AlumniEventsAndReunion = () => {
  // const navigate = useNavigate();

  return (
    <>
      {/* Section:Banner */}
      <section>
        <SecondaryBanner
          imageUrl={alumniEvents}
          title="About Us - Alumni - Events & Reunion"
          desc="Reconnect, Celebrate, and Network."
          themeColor="sh-contentimgsplit--green500-theme"
        />
      </section>

      {/* Section:About Text */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="global-subheading">
                Reconnect, reminisce, and rejuvenate at our alumni events and
                reunions. These gatherings are designed to bring our community
                together, celebrate achievements, and foster lifelong
                connections.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section:Upcoming Events */}
      <section className="container">
        <div className="row">
          <div className="col-12 mb-5">
            <h1 className="global-heading global-fwbold">upcoming events</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="al-upcoming">
              <div className="al-upcoming--header">
                <h4 className="global-subheading global-fw600">
                  Annual Alumni Meet 2024
                </h4>
                <Link to={"/"} className="global-orangeBtn">
                  Register Now
                </Link>
              </div>

              <ul className="al-upcoming--schedule">
                <li>
                  <b className="me-2">Date:</b>
                  <span>November 10, 2024</span>
                </li>
                <li>
                  <b className="me-2">Location:</b>
                  <span>Sitare University Campus, [City]</span>
                </li>
              </ul>

              <div>
                <p className="global-bodytext">
                  Join us for a day of celebration and networking at our Annual
                  Alumni Meet. This event includes keynote speeches, panel
                  discussions, and a gala dinner. Reconnect with old friends,
                  meet current students, and engage with faculty.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="al-upcoming">
              <div className="al-upcoming--header">
                <h4 className="global-subheading global-fw600">
                  Tech Talks and Innovation Expo
                </h4>
                <Link to={"/"} className="global-orangeBtn">
                  Register Now
                </Link>
              </div>

              <ul className="al-upcoming--schedule">
                <li>
                  <b className="me-2">Date:</b>
                  <span>December 25, 2024</span>
                </li>
                <li>
                  <b className="me-2">Location:</b>
                  <span>Virtual Event</span>
                </li>
              </ul>

              <div>
                <p className="global-bodytext">
                  Participate in our virtual Tech Talks and Innovation Expo.
                  Hear from alumni leaders in the tech industry, showcase your
                  innovative projects, and learn about the latest advancements
                  in Computer Science.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section:Past Events */}
      {/* Section:Upcoming Events */}
      <section className="container">
        <div className="row">
          <div className="col-12 mb-5">
            <h1 className="global-heading global-fwbold">past events</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="al-upcoming al-past">
              <div>
                <h4 className="global-subheading global-fw600 mb-4">
                  Alumni Meet 2023
                </h4>
              </div>

              <p className="global-bodytext">
                Our 2023 Alumni Meet was a resounding success, with over 500
                alumni in attendance. The event featured inspiring talks from
                distinguished alumni, interactive workshops, and a memorable
                evening gala. Check out the event photos and highlights video
                below.
              </p>

              <div>
                <ul className="al-past--options">
                  <li>
                    <Link to={""}>View Photos</Link>
                  </li>
                  <li>
                    <Link to={""}>Watch Video</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AlumniEventsAndReunion;
