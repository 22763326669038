import React from "react";
import { MegaMenu } from "primereact/megamenu";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { getMenuItems } from "./MegaMenuItems";
import Logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const menuItems = getMenuItems(navigate);

  return (
    <>
      {/* Section: Upper Header */}
      <section className="su-header--upper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="su-header--upperlist">
                <li>
                  <a href="tel:+917829758672" className="active">
                    +91 7829758672
                  </a>
                </li>
                {/* <li>
                  <Link to={"/news-updates"}>News & Updates</Link>
                </li>
                <li>
                  <Link to={'/events-activities'}>Events</Link>
                </li>
                <li>
                  <Link to={'/blogs'}>Blog</Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* Section: Upper Header End */}

      {/* Section: Header Menu */}
      <section className="su-header--menu ">
        <div className="container px-md-0 px-md-3">
          <div className="row align-items-center">
            <div className="col-md-2 col-6">
            <div className="logo_header">
                <Link to={"/"}>
                  <img src={Logo} alt="logo" draggable="false" />
                </Link>
              </div>
            </div>
            <div className="col-md-10 col-6">
           
              <MegaMenu
                model={menuItems}
                breakpoint="992px"
                className="w-100"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Section: Header Menu End*/}
    </>
  );
};

export default Header;
