import React from "react";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import stayConnected from "../../assets/images/stay_connected_bg.webp";
import "./style.scss";
import { Carousel } from "primereact/carousel";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { ALUMNI_CARDS_DATA } from "../../constants";
import { useNavigate } from "react-router-dom";
import MenuCards from "../../components/sections/MenuCards";
import ImgAER from '../../assets/images/img_AER.webp';
import ImgBenefits from '../../assets/images/img_benefits.webp';
import ImgGallery from '../../assets/images/img_gallery.webp';

const CAROUSEL_DATA = [
  {
    id: 1,
    title: "Global Reach",
    desc: "Our alumni are spread across the globe, working in leading tech companies, startups, academia, and research institutions.",
    image: "https://images.pexels.com/photos/1065084/pexels-photo-1065084.jpeg",
  },
  {
    id: 2,
    title: "Diverse Fields",
    desc: "From AI and machine learning to cybersecurity and software engineering, our alumni excel in a wide range of fields.",
    image: "https://images.pexels.com/photos/227294/pexels-photo-227294.jpeg",
  },
  {
    id: 3,
    title: "Lifelong Connections",
    desc: "Forge lifelong friendships and professional connections through our alumni network.",
    image: "https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg",
  },
];

const StayConnected = () => {
  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    if (route.includes("http:") || route.includes("https:")) {
      window.open(route, "_blank")
    } else {
      navigate(`/${route}`);
    }
  };


  const responsiveOption = [
    // {
    //   breakpoint: '3600px',
    //   numVisible: 1,
    //   numScroll: 1,
    // },
    // {
    //   breakpoint: '1200px',
    //   numVisible: 3,
    //   numScroll: 1,
    // },
    // {
    //     breakpoint: '1024px',
    //     numVisible: 2,
    //     numScroll: 1,
    // },
    {
      breakpoint: "768px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const productTemplate = (item) => {
    return (
      <div className="ts-leaders--carouselitem m-sm-2">
        <img
          className="ts-leaders--carouselitem-img"
          alt="Profile Pic"
          src={item?.image}
          draggable='false'
        />
        <div className="ts-leaders--carouselitem-content">
          <h3 className="global-subheading global-fw600 mb-3">{item?.title}</h3>
          <p className="ts-leaders--carouselitem--desc">{item?.desc}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* Section:Banner */}
      <section>
        <SecondaryBanner
          imageUrl={stayConnected}
          title="About Us - Alumni - Stay Connected"
          desc="Inspire the next Gen."
          themeColor="sh-contentimgsplit--green500-theme"
        />
      </section>

      <section className="container">
        <div className="st-heading">
          <h1 className="global-heading text-center mb-5">
            Welcome to the Sitare University <br /> Alumni Community
          </h1>
          <p className="global-bodytext global-fw600 text-center">
            Welcome to the Sitare University Alumni page, your gateway to
            staying connected with fellow graduates, current students, and the
            broader Sitare community. As a member of this vibrant network, you
            are part of a unique group of individuals who have experienced
            transformative education and are making significant contributions to
            the world.
          </p>
        </div>
      </section>

      {/* Section: Internship Insights */}
      <section className="st-network ts-leaders">
        <div className="container">
          <h4 className="global-heading">Our Alumni Network</h4>
          <div className="pt-5">
            <Carousel
              value={CAROUSEL_DATA}
              numVisible={1}
              numScroll={1}
              responsiveOptions={responsiveOption}
              className="custom-carousel in-insights--internship-carousel"
              circular={false}
              showIndicators={false}
              autoplayInterval={0}
              itemTemplate={productTemplate}
              prevIcon={<i className="fa-solid fa-angle-left"></i>}
              nextIcon={<i className="fa-solid fa-angle-right"></i>}
              indicatorsContentClassName="gdhshds"
            />
          </div>
        </div>
      </section>

      {/* Section: Intake Apply */}
      <section className="global-blue--strip">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="global-blue">
                <div>
                  <p className="global-blue--stripapplytitle">
                    Staying connected with Sitare University<br /> and fellow alumni is
                    easier than ever.
                  </p>
                  <h2 className="global-blue--stripapplybtn">Stay Connected</h2>
                </div>

                <Link
                  to={"/alumni-stay-connected"}
                  className="global-blue--striparrow"
                >
                  <i className="fa-solid fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>

        </div>
      </section>

      {/* Section: Why Internship Carousel pl-whyinternship  */}
      <section className="st-stories">
        <section className="container">
          <h1 className="global-heading mb-2">Alumni Stories</h1>
          <p className="global-bodytext">
            Our alumni are making waves in their respective fields, and we love
            to celebrate their successes. Here are some inspiring stories from
            our graduates
          </p>

          <div className="cp-whyinternship--carousel">
            <OwlCarousel
              items={1}
              className="owl-theme ps-3"
              loop
              nav
              dotClass="carousel-dot"
              dotsClass="carousel-dot-wrapper"
              navIcon={["PREV", "NEXT"]}
              margin={20}

            >
              <div className="position-relative st-stories--carouselitembox">
                <div className="st-stories--carouselItem">
                  <div className="st-stories-crimg">
                    <img
                      src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg"
                      alt="Story-image"
                      draggable="false"
                    />
                  </div>
                  <div className="st-stories-crcontent">
                    <p className="global-bodytext global-lineclamp">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.&quot;
                    </p>
                    <h3 className="global-subheading global-fw600 mt-5">
                      Name of the Alumni
                    </h3>
                    <p className="global-bodytext" data-wrap="wrap">
                      Degree name, Batch of 2022
                    </p>
                  </div>
                </div>
              </div>
              <div className="position-relative st-stories--carouselitembox">
                <div className="st-stories--carouselItem">
                  <div className="st-stories-crimg">
                    <img
                      src="https://images.pexels.com/photos/227294/pexels-photo-227294.jpeg"
                      alt="Story-image"
                      draggable='false'
                    />
                  </div>
                  <div className="st-stories-crcontent">
                    <p className="global-bodytext global-lineclamp">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.&quot;
                    </p>
                    <h3 className="global-subheading global-fw600 mt-5">
                      Name of the Alumni
                    </h3>
                    <p className="global-bodytext" data-wrap="wrap">
                      Degree name, Batch of 2022
                    </p>
                  </div>
                </div>
              </div>
              <div className="position-relative st-stories--carouselitembox">
                <div className="st-stories--carouselItem">
                  <div className="st-stories-crimg">
                    <img
                      src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg"
                      alt="Story-image"
                    />
                  </div>
                  <div className="st-stories-crcontent">
                    <p className="global-bodytext global-lineclamp">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.&quot;
                    </p>
                    <h3 className="global-subheading global-fw600 mt-5">
                      Name of the Alumni
                    </h3>
                    <p className="global-bodytext" data-wrap="wrap">
                      Degree name, Batch of 2022
                    </p>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </section>
      </section>

      <section className="container">
        <div className="st-gallery">
          <div className="st-gallery--item">
            <img
              className="st-gallery--img" alt="Profile Pic"
              src={ImgBenefits}
              draggable='false'
            />
            <h3 className="st-gallery--text">Benefits</h3>
          </div>
          <div className="st-gallery--item">
            <img
              className="st-gallery--img" alt="Profile Pic"
              src={ImgGallery}
              draggable='false'
            />
            <h3 className="st-gallery--text">Gallery</h3>
          </div>
          <div className="st-gallery--item">
            <img
              className="st-gallery--img" alt="Profile Pic"
              src={ImgAER}
              draggable='false'
            />
            <h3 className="st-gallery--text">Alumni Events & Reunions</h3>
          </div>

        </div>
      </section>

      {/* Section: Bottom Menu Cards */}
      <section className="global-bgmenucard">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <MenuCards
                data={ALUMNI_CARDS_DATA}
                handleMenuClick={handleMenuClick}
                themeColor="sh-menuCard--green-theme"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StayConnected;
