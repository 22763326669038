import React from 'react';
import SecondaryBanner from '../../components/sections/SecondaryBanner';
import PeopleImg from "../../assets/images/people_Img.webp";
import MenuCards from '../../components/sections/MenuCards';
import { ALUMNI_CARDS_DATA } from '../../constants';
import { useNavigate } from 'react-router-dom';

const AlumniPage = () => {
    const navigate = useNavigate();

    const handleMenuClick = (route) => {
        if (route.includes("http:") || route.includes("https:")) {
            window.open(route, "_blank")
        } else {
            navigate(`/${route}`);
        }
    }

    return (
        <>
            <section className='mb-5'>
                <SecondaryBanner
                    imageUrl={PeopleImg}
                    title="Alumni"
                    desc="Connecting the Past, Inspiring the Future."
                    themeColor="sh-contentimgsplit--green500-theme"
                />
            </section>
            <section className='container py-lg-5 mb-5'>
                <MenuCards data={ALUMNI_CARDS_DATA} handleMenuClick={handleMenuClick} themeColor="sh-menuCard--green-theme" />
            </section>
        </>
    )
}

export default AlumniPage