import React from "react";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import Bannerimg from "../../assets/images/home/banner_1.webp";
import subBanner1 from "../../assets/images/about/subBanner1.webp";
import subBanner2 from "../../assets/images/about/subBanner2.webp";
import MenuCards from "../../components/sections/MenuCards";
import { MENU_CARDS_DATA } from "../../constants";
import { useNavigate } from "react-router-dom";
import "./style.scss";

const WhoWeAre = () => {
  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    if (route.includes("http:") || route.includes("https:")) {
      window.open(route, "_blank")
    } else {
      navigate(`/${route}`);
    }
  };

  return (
    <>
      {/* Section: Banner */}
      <section>
        <SecondaryBanner
          imageUrl={Bannerimg}
          title="about us - who we are"
          desc="Empowering Minds, Shaping Futures"
        />
      </section>

      {/* Section: Our Past */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <div className="row">
                <div className="col-md-5 col-12">
                  <h1 className="global-heading">Our past</h1>
                  <h3 className="global-subheading">Our Foundation</h3>
                </div>
                <div className="col-md-7 col-12">
                  <p className="global-bodytext mb-4">
                    Sitare Foundation, established in 2016 by Dr. Amit Singhal
                    and Mrs. Shilpa Singhal, aimed to empower 50,000 bright
                    underprivileged students through education by 2050.
                    Initially, the foundation collaborated with schools in four
                    Indian cities to provide free education. By 2021,
                    recognizing the need for high-quality Computer Science
                    education due to gaps in the existing higher education
                    system, the founders conceptualized Sitare University. Under
                    the Madhya Pradesh Private University Act, Sitare University
                    was founded to empower India&apos;s brightest underprivileged
                    students.
                  </p>
                  <p className="global-bodytext">
                    In 2022, the university opened in partnership with Shri
                    Ramswaroop Memorial University, offering a BTech in Computer
                    Science to an inaugural batch of 23 students. 44 students joined the program in 2023 and 158 students joined in 2024, marking a significant milestone
                    in transforming lives through education. With passionate
                    support from a diverse community of visionaries, both
                    locally and globally, Sitare University continues to strive
                    towards its mission of creating world-class computer
                    scientists from underprivileged backgrounds.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Image */}
      <section className="sh-banner">
        <div className="container">
          <img
            className="sh-banner--image"
            src={subBanner1}
            draggable='false'
            alt="banner"
          />
        </div>
      </section>

      {/* Section: Our Present */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <div className="row">
                <div className="col-md-5 col-12">
                  <h1 className="global-heading">Our present</h1>
                  <h3 className="global-subheading">
                    Our Growth and
                    <br /> Achievements
                  </h3>
                </div>
                <div className="col-md-7 col-12">
                  <p className="global-bodytext mb-4">
                    Currently, Sitare University has a vibrant community of about 220
                    students spanning its first three years. Almost all students
                    benefit from a full 100% scholarship, which includes
                    tuition, hostel and mess fees, ensuring that financial
                    constraints never hinder their pursuit of knowledge.
                    Operating out of SRMU Lucknow for the time being, all our
                    students are currently immersed in this dynamic academic
                    environment.
                  </p>
                  <p className="global-bodytext">
                    In just 3 short years, Sitare University has been able to
                    attract faculty members with excellent educational
                    backgrounds whose unwavering dedication is focused solely on
                    delivering unparalleled education. Beyond the classroom,
                    we&apos;ve ensured nearly every student secures enriching paid
                    summer internships annually. These opportunities,
                    predominantly in cutting-edge startups, not only foster
                    real-world skills but also ignite a passion for innovation
                    and entrepreneurship among our budding scholars.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Our Present Banner */}
      <section className="sh-banner">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <img
                className="sh-banner--image"
                src={subBanner2}
                draggable='false'
                alt="banner"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Section: Our Future */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <div className="row">
                <div className="col-md-5 col-12">
                  <h1 className="global-heading">Our future</h1>
                  <h3 className="global-subheading">
                    Our Vision and Aspirations
                  </h3>
                </div>
                <div className="col-md-7 col-12">
                  <p className="global-bodytext mb-4">
                    We are excited about our progress and plan to double our
                    student intake each year, aiming to welcome 1,000 new
                    students annually by 2030, positioning us as a top Computer
                    Science institute in India. Our curriculum ensures the best
                    job opportunities in India and abroad, with many students
                    expected to build startups through our venture capitalist
                    and mentor network.
                  </p>
                  <p className="global-bodytext">
                    Within two years, we anticipate completing our formal
                    registration and preparing our vibrant new campus.
                    Construction is underway on a 38-acre site near Dewas on the
                    Bhopal-Indore highway, with plans to move in by September
                    2025.
                  </p>
                  <p className="global-bodytext">
                    Sitare University envisions offering transformative
                    education to bright, underprivileged students, enriching
                    India&apos;s pool of Computer Science talent. With our dedicated
                    team, we are confident in creating a future where education
                    opens doors and changes lives.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="global-bgmenucard">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <MenuCards
                data={MENU_CARDS_DATA}
                handleMenuClick={handleMenuClick}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhoWeAre;
