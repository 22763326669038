import React from "react";
import { useNavigate } from "react-router-dom";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import thumbnail1 from "../../assets/images/studentProject/biteBase.webp";
import thumbnail2 from "../../assets/images/studentProject/sakshatkar.webp";
import thumbnail3 from "../../assets/images/studentProject/gramAI.webp";
import thumbnail4 from "../../assets/images/studentProject/cityGraph.webp";
import thumbnail5 from "../../assets/images/studentProject/img_edit.webp";
// import BannerImg from "../../assets/images/studentProject/studentProjects.webp";
import BannerImg from "../../assets/images/studentProject/stdProjects.webp";

import fullBanner from "../../assets/images/studentProject/stu_banner.webp";
import "./style.scss";
import "../../assets/styles/styles.scss";
import { Link } from "react-router-dom";
import { ACADEMIC_MENU_DATA } from '../../constants';
import MenuCards from '../../components/sections/MenuCards';

const StudentProjects = () => {
  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    if (route.includes("http:") || route.includes("https:")) {
      window.open(route, "_blank")
    } else {
      navigate(`/${route}`);
    }
  }

  return (
    <>
      {/* Section: Banner */}
      <section>
        <SecondaryBanner
          imageUrl={BannerImg}
          title="Academic Life - Projects - Student Projects"
          desc="Turning Concepts into Reality: Explore, Create, Share"
          themeColor="sh-contentimgsplit--yellow-theme"
        />
      </section>

      {/* Section: Real World Content */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="global-heading mb-4">
                From Classroom Concepts to Real-World Impact
              </h1>
              <p className="global-bodytext">
                At Sitare University, the Computer Science program emphasizes
                the importance of learning beyond the classroom through a
                variety of student projects. These projects are integral to the
                curriculum, allowing students to apply theoretical knowledge to
                practical, real-world problems. Students are encouraged to work
                on individual and team projects that span a wide range of
                topics, from developing software applications to creating
                innovative algorithms for data analysis. Faculty mentors guide
                students throughout the project lifecycle, providing feedback
                and support. The culmination of these projects often results in
                tangible products or services, which students can showcase in
                their portfolios, demonstrating their skills and ingenuity to
                potential employers.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Pedagogy Banner */}
      <section className="global-fullBanner">
        <div className="container">
          <img
            src={fullBanner}
            alt="Banner"
            draggable='false'
          />
        </div>
      </section>

      {/* Section: Featured Projects By Students */}
      <section className="container">
        <div className="row my-lg-5">
          <div className="col-12">
            <div className="">
              <h1 className="global-heading mb-4">
                Featured Projects by Students
              </h1>
              <p className="global-bodytext py-3">
                Our alumni are making waves in their respective fields, and we
                love to celebrate their successes. Here are some inspiring
                stories from our graduates
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Project Box */}
      <section className="container">
        <div className="row mb-5">

          {/* Project 1 */}
          <div className="col-12 mb-3">
            <div className="sp-project">
              <div className="sp-project--thumbnail">
                <img src={thumbnail1} alt="featured project" draggable="false" />
              </div>
              <div>
                <h3 className="global-subheading global-fw600 text-capitalize my-4">
                  BiteBase
                </h3>
                {/* View More Button */}
                <div className="mb-5">
                  <Link
                    to={
                      "https://github.com/nag2mani/BiteBase"
                    }
                    target="_blank"
                    className="global-orangeBtn"
                  >
                    View More Details
                  </Link>
                </div>

                {/* Project Detail Text */}
                <div className="mb-4">
                  <b className="global-label global-fw600">Student Name:</b>
                  <span className="global-label">Nagmani and team</span>
                </div>

                {/* Project Detail Text */}
                <div className="mb-4">
                  <b className="global-label global-fw600">Faculty Mentor:</b>
                  <span className="global-label">Dr. Achal Agrawal</span>
                </div>

                {/* Project Detail Text */}
                <div>
                  <p className="global-bodytext global-lineclamp">
                    BiteBase is a Django-based web application that provides real-time information from various trusted sources. It offers features like contests and hackathons, job listings, and breaking news updates.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Project 2 */}
          <div className="col-12 mb-3">
            <div className="sp-project">
              <div className="sp-project--thumbnail">
                <img src={thumbnail2} alt="featured project" draggable="false" />
              </div>
              <div>
                <h3 className="global-subheading global-fw600 text-capitalize my-4">
                  Sakshatkar
                </h3>
                {/* View More Button */}
                <div className="mb-5">
                  <Link
                    to={
                      "https://github.com/raushan22882917/sakshatkar-fr"
                    }
                    target="_blank"
                    className="global-orangeBtn"
                  >
                    View More Details
                  </Link>
                </div>

                {/* Project Detail Text */}
                <div className="mb-4">
                  <b className="global-label global-fw600">Student Name:</b>
                  <span className="global-label">Raushan and team</span>
                </div>

                {/* Project Detail Text */}
                <div className="mb-4">
                  <b className="global-label global-fw600">Faculty Mentor:</b>
                  <span className="global-label">Dr. Kushal Shah</span>
                </div>

                {/* Project Detail Text */}
                <div>
                  <p className="global-bodytext global-lineclamp">
                    Sakashatkaar is an AI-powered interview platform that automates HR, technical, and coding rounds with real-time feedback and in-depth analytics. It leverages NLP, machine learning, and automated code evaluation to ensure unbiased, efficient, and scalable hiring.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Project 3 */}
          <div className="col-12 mb-3">
            <div className="sp-project">
              <div className="sp-project--thumbnail">
                <img src={thumbnail3} alt="featured project" draggable="false" />
              </div>
              <div>
                <h3 className="global-subheading global-fw600 text-capitalize my-4">
                  GramAI
                </h3>
                {/* View More Button */}
                <div className="mb-5">
                  <Link
                    to={
                      "https://github.com/NarendraSinghChouhan/GramAi-languagelearningplatform"
                    }
                    target="_blank"
                    className="global-orangeBtn"
                  >
                    View More Details
                  </Link>
                </div>

                {/* Project Detail Text */}
                <div className="mb-4">
                  <b className="global-label global-fw600">Student Name:</b>
                  <span className="global-label">Narendra and team</span>
                </div>

                {/* Project Detail Text */}
                <div className="mb-4">
                  <b className="global-label global-fw600">Faculty Mentor:</b>
                  <span className="global-label">Dr. Achal Agrawal</span>
                </div>

                {/* Project Detail Text */}
                <div>
                  <p className="global-bodytext global-lineclamp">
                  GramAI is an AI-powered language learning platform designed to improve users&apos; English communication skills. It offers real-time personalized feedback on pronunciation, grammatical errors, word choice, and fluency. The platform includes interactive features like quizzes, games, and structured learning materials to make language learning engaging and effective.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Project 4 */}
          <div className="col-12 mb-3">
            <div className="sp-project">
              <div className="sp-project--thumbnail">
                <img src={thumbnail4} alt="featured project" draggable="false" />
              </div>
              <div>
                <h3 className="global-subheading global-fw600 text-capitalize my-4">
                  CityGraph
                </h3>
                {/* View More Button */}
                <div className="mb-5">
                  <Link
                    to={
                      "https://github.com/sitareuniversity/classicalai/tree/main/CityGraph"
                    }
                    target="_blank"
                    className="global-orangeBtn"
                  >
                    View More Details
                  </Link>
                </div>
                {/* Project Detail Text */}
                <div className="mb-4">
                  <b className="global-label global-fw600">Student Name:</b>
                  <span className="global-label">Kirtan and team</span>
                </div>
                {/* Project Detail Text */}
                <div className="mb-4">
                  <b className="global-label global-fw600">Faculty Mentor:</b>
                  <span className="global-label">Dr. Kushal Shah</span>
                </div>

                {/* Project Detail Text */}
                <div>
                  <p className="global-bodytext global-lineclamp">
                    This is a curated dataset of actual and heuristic (straight
                    line) distances between various cities of India (Bharat),
                    that can be used for various assignments and projects
                    related to BFS, DFS, Greedy Best-First Search and A* Search
                    in a classical AI course. This dataset is the combined
                    efforts of the students of Sitare University.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Project 5 */}
          <div className="col-12 mb-3">
            <div className="sp-project">
              <div className="sp-project--thumbnail">
                <img src={thumbnail5} alt="featured project" draggable="false" />
              </div>
              <div>
                <h3 className="global-subheading global-fw600 text-capitalize my-4">
                  Image Editor
                </h3>
                {/* View More Button */}
                <div className="mb-5">
                  <Link
                    to={
                      "https://github.com/sitareuniversity/python/tree/main/Image%20Editor"
                    }
                    target="_blank"
                    className="global-orangeBtn"
                  >
                    View More Details
                  </Link>
                </div>

                {/* Project Detail Text */}
                <div className="mb-4">
                  <b className="global-label global-fw600">Student Name:</b>
                  <span className="global-label">
                    Rajat Malviya and Sandeep Kumar
                  </span>
                </div>

                {/* Project Detail Text */}
                <div className="mb-4">
                  <b className="global-label global-fw600">Faculty Mentor:</b>
                  <span className="global-label">Dr. Kushal Shah</span>
                </div>

                {/* Project Detail Text */}
                <div>
                  <p className="global-bodytext global-lineclamp">
                    ImageEditor is a Flask-based web application that allows
                    users to upload and edit images with various features such
                    as filters, cropping, blur, rotation, face detection, text
                    extraction, and an undo-redo system. Users can also download
                    their edited images.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* TODO(akshi): Add University Life related menu cards here */}

      {/* Section: Menu Cards */}
      <section className="global-bgmenucard pt-5">
        <div className='container'>
          <div className="row">
            <div className="col-12">
              <MenuCards data={ACADEMIC_MENU_DATA} handleMenuClick={handleMenuClick} themeColor="sh-menuCard--yellow-theme" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StudentProjects;
