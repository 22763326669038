export const MENU_CARDS_DATA = [
  // {
  //   id: 1,
  //   name: "Why Sitare",
  //   route: "why-sitare",
  // },
  {
    id: 2,
    name: "Who we are",
    route: "who-we-are",
  },
  // {
  //   id: 3,
  //   name: "Alumni",
  //   route: "stay-connected",
  // },
  {
    id: 4,
    name: "Our people",
    route: "our-people",
  },
  {
    id: 5,
    name: "Our campus",
    route: "facilities",
  },
  // {
  //   id: 6,
  //   name: "Work with us",
  //   route: "work-with-us",
  // },
  {
    id: 6,
    name: "Gallery",
    route: "about-gallery",
  },
];
export const ALUMNI_CARDS_DATA = [
  {
    id: 1,
    name: "Stay Connected",
    route: "stay-connected",
  },
  {
    id: 2,
    name: "Benefits",
    route: "alumni-benefits",
  },
  {
    id: 3,
    name: "Alumni Events",
    route: "alumni-events",
  },
  {
    id: 4,
    name: "Gallery",
    route: "alumni-gallery",
  },
];
export const ACADEMIC_MENU_DATA = [
  {
    id: 1,
    name: "curriculum",
    route: "curriculum",
  },
  {
    id: 2,
    name: "student projects",
    route: "student-projects",
  }
  // {
  //   id: 3,
  //   name: "Admissions",
  //   route: "https://admissions.sitare.org/",
  // },
  // {
  //   id: 4,
  //   name: "Academic Calendar",
  //   route: "",
  // },
];
export const PROJECTS_MENU_DATA = [
  {
    id: 1,
    name: "Student projects",
    route: "student-projects",
  },
  {
    id: 2,
    name: "sitare github",
    route: "",
  },
  {
    id: 3,
    name: "hugging face",
    route: "https://huggingface.co/ ",
  },
  {
    id: 4,
    name: "faculty projects",
    route: "faculty-projects",
  },
];
export const PLACEMENTS_CARDS_DATA = [
  {
    id: 1,
    name: "CoCo Summer of Fun",
    route: "coco-summer-of-fun",
  },
  {
    id: 2,
    name: "Internship - 3 months",
    route: "internship-three-month",
  },
  {
    id: 3,
    name: "Internship - 12 months",
    route: "internship-one-year",
  },
  // {
  //   id: 3,
  //   name: "job after graduation",
  //   route: "job-after-graduation",
  // },
  {
    id: 4,
    name: "Stories of transformation",
    route: "transformation-stories",
  },
];
export const UNIVERSITY_MENU_DATA = [
  {
    id: 1,
    name: "Facilities",
    route: "facilities",
  },
  {
    id: 2,
    name: "Events & Activities",
    route: "events-activities",
  },
];
export const FACILITIES_MENU_DATA = [
  {
    id: 1,
    name: "Hostels",
    route: "hostels",
  },
  {
    id: 2,
    name: "Cafeteria",
    route: "cafeteria",
  },
  // {
  //   id: 3,
  //   name: "Gym",
  //   route: "gym",
  // },
  // {
  //   id: 4,
  //   name: "Sports",
  //   route: "sports",
  // },
  {
    id: 5,
    name: "Auditorium",
    route: "auditorium",
  },
];
export const EVENTS_MENU_DATA = [
    {
      id: 1,
      name: "Clubs",
      route: "clubs",
    },
    // {
    //   id: 2,
    //   name: "Events",
    //   route: "university-event",
    // },
    // {
    //   id: 3,
    //   name: "Activites",
    //   route: "class-activities",
    // },
    // {
    //   id: 4,
    //   name: "Fests",
    //   route: "fests",
    // },
    // {
    //   id: 5,
    //   name: "Hackathons",
    //   route: "hackathon",
    // },
    {
      id: 6,
      name: "Gym",
      route: "gym",
    },
    {
      id: 7,
      name: "Sports",
      route: "sports",
    },
];
